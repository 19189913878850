import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';

const loading = () => <React.Fragment />;

const asyncRoute = (getComponent) => {
    const component = Loadable({
        loader: getComponent,
        loading,
    });
    // Loadab().then(() => {
    //     console.log("here")
    // });
    // return component;
    // setTimeout(() => {
    // component.preload();
    // }, 5000);
    Loadable.preloadReady().then(() => {
        component.preload();
    });
    return component;
};


export default asyncRoute;

// import React from 'react';

// export default function asyncComponent(getComponent) {
// class AsyncComponent extends React.Component {
//     static Component = null;

//     state = { Component: AsyncComponent.Component };

//     componentWillMount() {
//         console.log(AsyncComponent.Component)
//       if (!this.state.Component) {
//         getComponent().then(({default: Component}) => {
//           AsyncComponent.Component = Component
//           this.setState({ Component })
//         });
//       }
//     }
//     render() {
//       const { Component } = this.state
//       if (Component) {
//         return <Component {...this.props} />
//       }
//       return null
//     }
//   }

//   AsyncComponent.getComponent = () => {
//     return getComponent().then(({default: Component}) => {
//       AsyncComponent.Component = Component;
//     });
//   }

//   return AsyncComponent;
// }