import * as  config_local from './configuration/configuration_local';
import * as  config_dev from './configuration/configuration_dev';
import * as  config_stage from './configuration/configuration_stage';
import * as  config_test from './configuration/configuration_test';
import * as  config_prod from './configuration/configuration_prod';

// change here
const environment = 'prod';
// dev, prod, stage, local

let configuration = config_local;
if (environment === 'dev') {
  configuration = config_dev;
} else if (environment === 'stage') {
  configuration = config_stage;
} else if (environment === 'test') {
  configuration = config_test;
} else if (environment === 'prod') {
  configuration = config_prod;
}

export const apiBaseUrl = configuration.apiBaseUrl;
export const apiDruidBaseUrl = configuration.apiDruidBaseUrl;
export const authenticatio = configuration.apiBaseUrl;
export const apiFileUploadBaseUrl = configuration.apiFileUploadBaseUrl;
export const version = configuration.version;
export const domain = configuration.domain;
export const endPoints = configuration.endPoints;
export const constants = configuration.constants;
export const CONTEXT = configuration.CONTEXT;
export const torcaiTracking = configuration.torcaiTracking;
export const tomcatApiUrl = configuration.tomcatApiUrl;
export const iFrameApiUrl = configuration.iFrameApiUrl;
export const imageApiUrl = configuration.imageApiUrl;

// export const serverSidePageSize = 500;

export const supersetLoginUrl = configuration.supersetLoginUrl;
export const supersetDashboardUrl = configuration.apiBaseUrl;
export const supersetDefaultPassword = configuration.supersetDefaultPassword;

export const bidderCampaignPerformanrReportUrl = configuration.bidderCampaignPerformanrReportUrl;

export const bidderReportUrl = configuration.bidderReportUrl;

export const bidderMediaPlanningReportUrl = configuration.bidderMediaPlanningReportUrl;

export const bidderAprReportUrl = configuration.bidderAprReportUrl;

export const ionMediaReportUrl = configuration.ionMediaReportUrl;
export const ionRevenueReportUrl = configuration.ionRevenueReportUrl;
export const ionQpsReport = configuration.ionQpsReport;
export const ionReport = configuration.ionReport;
export const adUnitPublishLink = configuration.adUnitPublishLink;
export const vilAdUnitPublishLink = configuration.vilAdUnitPublishLink;
export const adUnitSingleJsLink = configuration.adUnitSingleJsLink;
export const cdnUrl = configuration.cdnUrl;
export const serverSidePageSize = 25;
