const routes = {
    mainRoute: 'torcai-ion',
    preSignIn: 'pre-signin',
    signIn: 'signin',
    forgotPassword: 'forgot-password',
    resetPassword: 'reset-password',
    notAuthorize: 'not-authorize',
    containers: {
        ion: 'ion',
        io: 'io',
        admin: 'admin',
        audience: 'audience',
        campaign: 'campaign',
        creative: 'creative',
        dashboard: 'dashboard',
        display: 'display',
        video: 'video',
        native: 'native',
        audio:'audio',
    },
    admin: {
        tenant: {
            name: 'tenant',
            add: 'add-tenant',
            edit: 'edit-tenant',
            editMgr: 'edit-mgr-tenant',
            view:'view-tenant',
            viewMgr:'view-mgr-tenant',
            addMgr:"add-mgr-tenant"
        },
        supplyPartner: {
            name: 'supply-partner',
            add: 'add-supply-partner',
            edit: 'edit-supply-partner'
        },
        platform: {
            name: 'platform',
            add: 'add-platform',
            edit: 'edit-platform'
        },
        account: {
            name: 'account',
            add: 'add-account',
            edit: 'edit-account',
            view:'view-account'
        },
        currencyRate: {
            name: 'currency-rate',
            add: 'add-currency-rate',
            edit: 'edit-currency-rate'
        },
        features: {
            name: 'features'
        },
        variable: {
            name: 'variable',
            add: 'add-variable',
            edit: 'edit-variable'
        },
        user: {
            name: 'user',
            add: 'add-user',
            edit: 'edit-user'
        },
        demandPartner: {
            name: 'demand',
            add: 'add-demand',
            edit: 'edit-demand'
        },
        roles: {
            name: 'roles',
            add: 'add-roles',
            edit: 'edit-roles'
        },
        supplyPath: {
            name: 'supply-path',
            add:'add-supply',
            edit:'edit-supply'
        },
        agreement:{
            name:'agreement',
            add:'add-agreement',
            edit:'edit-agreement'
        }
    },
    campaign: {
        campaign: {
            name: 'campaign',
            add: 'add-campaign',
            edit: 'edit-campaign'
        },
        pmp: {
            name: 'private-market-place-campaign'
        },
        siteList: {
            name: 'site-list'
        },
        // apr: {
        //     name: 'apr',
        //     add: 'add-apr',
        //     edit: 'edit-apr'
        // },
        addCampaign: 'add-campaign',
        editCampaign: 'edit-campaign'
    },
    creative: {
        display: {
            name: 'display',
            add: 'add-display',
            addBulk: 'add-bulk-csv',
            edit: 'edit-display',
        },
        video: {
            name: 'video',
            add: 'add-video',
            edit: 'edit-video',
        },
        native: {
            name: 'native',
            add: 'add-native',
            edit: 'edit-native',
        },
        audio: {
            name: 'audio',
            add: 'add-audio',
            edit: 'edit-audio',
        }
    },
    io: {
        addIo: 'add-io',
        editIo: 'edit-io',
        viewIo:'view-io'
    },
    ion: {
        adUnit: {
            name: 'adunit',
            add: 'add-adunit',
            edit: 'edit-adunit',
        },
        adUnitGroup: {
            name: 'adunit-group',
            add: 'add-adunit-group',
            edit: 'edit-adunit-group',
        },
        privateMarketPlace: {
            name: 'private-market-place',
            add: 'add-private-market-place',
            edit: 'edit-private-market-place',
            addSeat: 'add-seat'
        },
        mediation: {
            name: 'mediation',
            add: 'add-mediation',
            edit: 'edit-mediation',
            addRule: 'add-mediation-rule'
        },
        mediationAdGroup: {
            name: 'mediation-add-group',
            add: 'add-mediation-add-group',
            edit: 'add-mediation-edit-group',
        },
        tagldMapping: {
            tagldMapping: 'tagld-mapping',
            tagldMappingList: 'tagld-mapping-list',
            tagldMappingBulkUpload: 'tagld-mapping-bulk-upload',
            add: 'add-tagld-mapping',
        },
    },
    dashboard: {
        bidder: {
            bidder: 'bidder',
            campaignPerformanceReport: 'campaign-performance-report',
            bidderReport: 'bidder-report',
            mediaPlanningReport: 'media-planning-report'
        },
        dataPartner: {
            dataPartner: 'data-partner',
            segmentReport: 'segment-report',
            campaignReport: 'campaign-report',
            adminReport: 'admin-report',
        },
        ion: {
            ionReport: 'ion-report',
            mediaReport: 'media-report',
            revenueReport: 'revenue-report',
            qpsReport: 'qps-report',
            ionExchange: 'ion-exchange',
        },
        // dataPartner: {
        //     dataPartner: 'data-partner',
        //         dailyReport: 'daily-report',
        //         monthlyReport: 'monthly-report'
        // },
    },
    audience: {
        audienceHierarchy: {
            name: 'audience-hierarchy'
        },
        hierarchyManager: {
            name: 'hierarchy-manager',
            add: 'add-hierarchy',
            edit: 'edit-hierarchy'
        },
        apr: {
            name: 'apr',
            add: 'add-apr',
            edit: 'edit-apr'
        },
        taxonomyManager: {
            name: 'taxonomy-manager',
            add: 'add-taxonomy-manager',
            edit: 'edit-taxonomy-manager'
        },
        dataCapture: {
            name: 'data-capture',
            add: 'add-data-capture',
            edit: 'edit-data-capture'
        },
    }

};

export default routes;