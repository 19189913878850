import routes from '../routes';
export const ROUTE = {
  'DASHBOARD-BIDDER-VIEW-LOGIN :: DASHBOARD :: BIDDER :: CAMPAIGN PERFORMANCE REPORT': `/${routes.mainRoute}/${routes.containers.dashboard}/${routes.dashboard.bidder.bidder}/${routes.dashboard.bidder.campaignPerformanceReport}`,
  'DASHBOARD-BIDDER-VIEW-LOGIN :: DASHBOARD :: BIDDER :: BIDDER REPORT': `/${routes.mainRoute}/${routes.containers.dashboard}/${routes.dashboard.bidder.bidder}/${routes.dashboard.bidder.bidderReport}`,
  'DASHBOARD-BIDDER-VIEW-LOGIN :: DASHBOARD :: BIDDER :: MEDIA PLANING REPORT': `/${routes.mainRoute}/${routes.containers.dashboard}/${routes.dashboard.bidder.bidder}/${routes.dashboard.bidder.mediaPlanningReport}`,
  'DASHBOARD-BIDDER-VIEW-LOGIN :: DASHBOARD :: BIDDER :: APR REPORT': `/${routes.mainRoute}/${routes.containers.dashboard}/${routes.dashboard.bidder.bidder}/${routes.dashboard.bidder.aprReport}`,

  'DASHBOARD-ION-VIEW-LOGIN :: DASHBOARD :: ION :: MEDIA REPORT': `/${routes.mainRoute}/${routes.containers.dashboard}/${routes.dashboard.ion.ionReport}/${routes.dashboard.ion.mediaReport}`,
  'DASHBOARD-ION-VIEW-LOGIN :: DASHBOARD :: ION :: BILLING REPORT': `/${routes.mainRoute}/${routes.containers.dashboard}/${routes.dashboard.ion.ionReport}/${routes.dashboard.ion.revenueReport}`,
  'DASHBOARD-ION-VIEW-LOGIN :: DASHBOARD :: ION :: QPS REPORT': `/${routes.mainRoute}/${routes.containers.dashboard}/${routes.dashboard.ion.ionReport}/${routes.dashboard.ion.qpsReport}`,
  'DASHBOARD-ION-VIEW-LOGIN :: DASHBOARD :: ION :: ION REPORT': `/${routes.mainRoute}/${routes.containers.dashboard}/${routes.dashboard.ion.ionReport}/${routes.dashboard.ion.ionExchange}`,

    
  
  'DASHBOARD-DATA PARTNER REPORTS-VIEW-LOGIN :: DASHBOARD :: DATA PARTNER REPORTS :: ADMIN REPORT': `/${routes.mainRoute}/${routes.containers.dashboard}/${routes.dashboard.dataPartner.dataPartner}/${routes.dashboard.dataPartner.adminReport}`,
  'DASHBOARD-DATA PARTNER REPORTS-VIEW-LOGIN :: DASHBOARD :: DATA PARTNER REPORTS :: SEGMENT WISE REPORT': `/${routes.mainRoute}/${routes.containers.dashboard}/${routes.dashboard.dataPartner.dataPartner}/${routes.dashboard.dataPartner.segmentReport}`,
  'DASHBOARD-DATA PARTNER REPORTS-VIEW-LOGIN :: DASHBOARD :: DATA PARTNER REPORTS :: CAMPAIGN PERFORMANCE REPORT': `/${routes.mainRoute}/${routes.containers.dashboard}/${routes.dashboard.dataPartner.dataPartner}/${routes.dashboard.dataPartner.campaignReport}`,


  'IO-IO-VIEW-LOGIN :: IO': `/${routes.mainRoute}/${routes.containers.io}`,

  'CAMPAIGN-CAMPAIGN-VIEW-LOGIN :: CAMPAIGN': `/${routes.mainRoute}/${routes.containers.campaign}`,

  'CREATIVE-DISPLAY-VIEW-LOGIN :: CREATIVE :: DISPLAY': `/${routes.mainRoute}/${routes.containers.creative}/${routes.containers.display}`,
  'CREATIVE-VIDEO-VIEW-LOGIN :: CREATIVE :: VIDEO': `/${routes.mainRoute}/${routes.containers.creative}/${routes.containers.video}`,
  'CREATIVE-NATIVE-VIEW-LOGIN :: CREATIVE :: NATIVE': `/${routes.mainRoute}/${routes.containers.creative}/${routes.containers.native}`,

  'EXCHANGE-AD UNIT-VIEW-LOGIN :: EXCHANGE :: AD UNIT': `/${routes.mainRoute}/${routes.containers.ion}/${routes.ion.adUnit.name}`,
  'EXCHANGE-AD UNIT GROUPS-VIEW-LOGIN :: EXCHANGE :: AD UNIT GROUPS': `/${routes.mainRoute}/${routes.containers.ion}/${routes.ion.adUnitGroup.name}`,
  'EXCHANGE-PRIVATE MARKET PLACE-VIEW-LOGIN :: EXCHANGE :: PRIVATE MARKET PLACE ': `/${routes.mainRoute}/${routes.containers.ion}/${routes.ion.privateMarketPlace.name}`,
  'EXCHANGE-MEDIATION AD GROUP-VIEW-LOGIN :: EXCHANGE :: AD UNIT GROUPS': `/${routes.mainRoute}/${routes.containers.ion}/${routes.ion.mediationAdGroup.name}`,
  'EXCHANGE-MEDIATION-VIEW-LOGIN :: EXCHANGE :: MEDIATON': `/${routes.mainRoute}/${routes.containers.ion}/${routes.ion.mediation.name}`,

  'ADMIN-TENANT-VIEW-LOGIN :: ADMIN :: TENANT': `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.tenant.name}`,
  'ADMIN-FEATURES-VIEW-LOGIN :: ADMIN :: FEATURES': `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.features.name}`,
  'ADMIN-USERS-VIEW-LOGIN :: ADMIN :: USER': `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.user.name}`,
  'ADMIN-DEMAND PARTNER-VIEW-LOGIN :: ADMIN :: DEMAND PARTNER': `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.demandPartner.name}`,
  'ADMIN-SUPPLY PARTNER-VIEW-LOGIN :: ADMIN :: SUPPLY PARTNER': `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.supplyPartner.name}`,
  'ADMIN-VARIABLE-VIEW-LOGIN :: ADMIN :: VARIABLE': `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.variable.name}`,
  'ADMIN-ROLES-VIEW-LOGIN :: ADMIN :: ROLES': `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.roles.name}`,
  'ADMIN-AGREEMENT-VIEW-LOGIN :: ADMIN :: AGREEMENT': `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.agreement.name}`,
  'ADMIN-SUPPLY PATH-VIEW-LOGIN :: ADMIN :: SUPPLY PATH': `/${routes.mainRoute}/${routes.containers.admin}/${routes.admin.supplyPath.name}`,

  'AUDIENCE-AUDIENCE HIERARCHY-VIEW-LOGIN :: AUDIENCE :: AUDIENCE HIERARCHY': `/${routes.mainRoute}/${routes.containers.audience}/${routes.audience.audienceHierarchy.name}`,
  'AUDIENCE-HIERARCHY MANAGER-VIEW-LOGIN :: AUDIENCE :: HIERARCHY MANAGER': `/${routes.mainRoute}/${routes.containers.audience}/${routes.audience.hierarchyManager.name}`,
  'AUDIENCE-APR-VIEW-LOGIN :: AUDIENCE :: APR': `/${routes.mainRoute}/${routes.containers.audience}/${routes.audience.apr.name}`,
  'AUDIENCE-TAXONOMY MANAGER-VIEW-LOGIN :: AUDIENCE :: SEGMENT': `/${routes.mainRoute}/${routes.containers.audience}/${routes.audience.taxonomyManager.add}`,
  'AUDIENCE-DATA CAPTURE-VIEW-LOGIN :: AUDIENCE :: DATA CAPTURE': `/${routes.mainRoute}/${routes.containers.audience}/${routes.audience.dataCapture.name}`,

}
