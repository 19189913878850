import { Component } from "react";
import { withRouter } from "react-router-dom";
import $ from 'jquery';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (!(this.props.location.pathname == prevProps.location.pathname)) {
      $('html, body').animate({ scrollTop: 0 }, 500);
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
