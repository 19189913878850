// added namespacing to avoid interference with other plugins or redux stores

export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';

export const END_AJAX_CALL = 'END_AJAX_CALL';

export const END_AJAX_CALL_CONFIG = 'END_AJAX_CALL_CONFIG';

export const BEGIN_AJAX_CALL_CONFIG = 'BEGIN_AJAX_CALL_CONFIG';

export const PRE_SIGN_IN_SUCCESS = 'PRE_SIGN_IN_SUCCESS';

export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';

export const DISPATCH_PRE_SIGNIN_STATE_SUCCESS = 'DISPATCH_PRE_SIGNIN_STATE_SUCCESS';

export const GET_CONFIGURATION_DATA = 'GET_CONFIGURATION_DATA';

export const GET_FILTER_LIST = 'GET_FILTER_LIST';

export const GET_ION_FILTER_LIST = 'GET_ION_FILTER_LIST';

export const GET_NEW_SEGMENT_LIST_ALL = 'GET_NEW_SEGMENT_LIST_ALL';

// -----* ION_REPORT * ----

export const GET_ADD_UNIT_LIST = 'GET_ADD_UNIT_LIST';

export const GET_ADD_UNIT_GROUP_LIST = 'GET_ADD_UNIT_GROUP_LIST';

export const GET_PMP_LIST = 'GET_PMP_LIST';

export const GET_ROADBLOCK_LIST = 'GET_ROADBLOCK_LIST';

export const GET_TAGLD_MAPPING_LIST = 'GET_TAGLD_MAPPING_LIST';

export const GET_TAGLD_MAPPING_ADUNIT_ALL_LIST = 'GET_TAGLD_MAPPING_ADUNIT_ALL_LIST';

export const GET_TAGLD_MAPPING_BULK_LIST = 'GET_TAGLD_MAPPING_BULK_LIST';


// -----* GET_ION_REPORT * ----

export const GET_MEDIA_REPORT = 'GET_MEDIA_REPORT';

export const GET_QPS_REPORT = 'GET_QPS_REPORT';

export const GET_REVENUE_REPORT = 'GET_REVENUE_REPORT';

export const GET_ION_EXCHANGE_REPORT = 'GET_ION_EXCHANGE_REPORT';

// ---- * GET_BIDDER_REPORT * ----

export const GET_BIDDER_REPORT = 'GET_BIDDER_REPORT';

export const GET_USER_REACH_REPORT = 'GET_USER_REACH_REPORT';

export const ADD_TENANT = "ADD_TENANT";

export const GET_MEDIA_PLANNING_REPORT = 'GET_MEDIA_PLANNING_REPORT';

export const GET_CAMPAIGN_PERFORMANCE_REPORT = 'GET_CAMPAIGN_PERFORMANCE_REPORT';

export const GET_CAMPAIGN_PERFORMANCE_REPORT_FILTER_LIST = 'GET_CAMPAIGN_PERFORMANCE_REPORT_FILTER_LIST';

export const GET_DATA_PARTNER_CUSTOM_FILTER_LIST = 'GET_DATA_PARTNER_CUSTOM_FILTER_LIST';

export const GET_SAVE_REPORT = 'GET_SAVE_REPORT';

export const GET_SAVE_REPORT_DOWNLOAD = "GET_SAVE_REPORT_DOWNLOAD";

export const GET_GENERATE_REPORT ="GET_GENERATE_REPORT";

export const GET_REFRESH_REPORT="GET_REFRESH_REPORT";

export const GET_MEDIA_SAVE_REPORT="GET_MEDIA_SAVE_REPORT";

export const GET_CAMPAIGN_LIST_ALL_REPORT = 'GET_CAMPAIGN_LIST_ALL_REPORT';

export const GET_CAMPAIGN_CATEGORY_TYPE_LIST = 'GET_CAMPAIGN_CATEGORY_TYPE_LIST';


// ----* io ---
export const GET_IO_LIST_ALL_REPORT = 'GET_IO_LIST_ALL_REPORT';

export const GET_IO_ADVERTISER_LIST_REPORT = 'GET_IO_ADVERTISER_LIST_REPORT';

export const GET_EXCHANGE_LIST_REPORT = 'GET_EXCHANGE_LIST_REPORT';


// ----* Campaign * -----
export const GET_CAMPAIGN_SUB_CATEGORY = 'GET_CAMPAIGN_SUB_CATEGORY';

export const GET_ADVERTISER_CREATIVE_GROUP = 'GET_ADVERTISER_CREATIVE_GROUP';

// campaign  Private Market Place 
export const GET_PRIVATE_MARKET_PLACE_LIST = 'GET_PRIVATE_MARKET_PLACE_LIST';

// ---* country/state/city * ---
export const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST';

export const GET_STATE_LIST = 'GET_STATE_LIST';

export const GET_CITY_LIST = 'GET_CITY_LIST';

// ---* state all/city all * ---
export const GET_STATE_LIST_ALL = 'GET_STATE_LIST_ALL';

export const GET_CITY_LIST_ALL = 'GET_CITY_LIST_ALL';

// ---- * Admin * ---

// Tenant 
export const GET_TENANT_LIST_ALL = 'GET_TENANT_LIST_ALL';

export const GET_ACCOUNTS_LIST = 'GET_ACCOUNTS_LIST'

//Supply Partner
export const GET_SUPPLY_PARTNER_LIST_ALL = 'GET_SUPPLY_PARTNER_LIST_ALL';

//Variable
export const GET_ADMIN_VARIABLE_LIST_ALL = 'GET_ADMIN_VARIABLE_LIST_ALL';

// User
export const GET_ADMIN_USER_LIST_ALL = 'GET_ADMIN_USER_LIST_ALL';

// roles
export const GET_ADMIN_ROLES_LIST_ALL = 'GET_ADMIN_ROLES_LIST_ALL';

export const GET_ADMIN_MAIN_ROLES_LIST_ALL = "GET_ADMIN_MAIN_ROLES_LIST_ALL";
//platform 
export const GET_PLATFORM_LIST_ALL = 'GET_PLATFORM_LIST_ALL';

//  Demand partner name filter
export const GET_DEMAND_PARTNER_NAME_LIST_ALL = 'GET_DEMAND_PARTNER_NAME_LIST_ALL';

// Demandpartner 
export const GET_DEMAND_PARTNER_LIST_ALL = 'GET_DEMAND_PARTNER_LIST_ALL';

//  mediation demand partner list
export const GET_MEDIATION_DEMAND_PARTNER_SEAT_LIST_ALL = "GET_MEDIATION_DEMAND_PARTNER_SEAT_LIST_ALL";

// Demandpartner Seat
export const GET_DEMAND_PARTNER_SEAT_LIST_ALL = "GET_DEMAND_PARTNER_SEAT_LIST_ALL"

export const GET_TENANT_DEMAND_PARTNER_LIST = 'GET_TENANT_DEMAND_PARTNER_LIST';

export const GET_ADMIN_SUPPLY_PARTNER_LIST_ALL = 'GET_ADMIN_SUPPLY_PARTNER_LIST_ALL';

export const GET_TENANT_SUPPLY_PARTNER_LIST_ALL = 'GET_TENANT_SUPPLY_PARTNER_LIST_ALL';

// Currency Rate 
export const GET_CURRENCY_RATE_LIST = 'GET_CURRENCY_RATE_LIST';

export const ADD_CURRENCY_RATE="ADD_CURRENCY_RATE";

// supplyPath
export const GET_ADMIN_ION_SUPPLY_PARTNER_LIST_ALL = 'GET_ADMIN_ION_SUPPLY_PARTNER_LIST_ALL';

export const GET_ADMIN_PVT_SUPPLY_PARTNER_LIST_ALL = 'GET_ADMIN_PVT_SUPPLY_PARTNER_LIST_ALL';

// feature
export const GET_LIST_FEATURE_ALL = 'GET_LIST_FEATURE_ALL';

export const GET_FEATURE_LIST_ALL = 'GET_FEATURE_LIST_ALL';

// mediation 
export const GET_MEDIATION_LIST = 'GET_MEDIATION_LIST';


// agreement 
export const GET_AGREEMENT_LIST_ALL = 'GET_AGREEMENT_LIST_ALL';

export const GET_AGREEMENT_CONNECTION_LIST = 'GET_AGREEMENT_CONNECTION_LIST';

export const GET_AGREEMENT_ACTIVE_SEAT_LIST = "GET_AGREEMENT_ACTIVE_SEAT_LIST";

export const GET_AGREEMENT_PARENT_TENANT_LIST = "GET_AGREEMENT_PARENT_TENANT_LIST";

export const GET_PVT_EXCHANGE_TENANT_LIST = "GET_PVT_EXCHANGE_TENANT_LIST";

export const GET_PVT_DEMAND_PARTNER_LIST ="GET_PVT_DEMAND_PARTNER_LIST";

// Add Mediation AdGroup
export const GET_MEDIATION_AD_GROUP_LIST = 'GET_MEDIATION_AD_GROUP_LIST';

// user Permission
export const GET_USER_PERMISSION = 'GET_USER_PERMISSION';

// ---- * Audience * ---

// Branded
export const GET_BRANDED_HIERARCHY_LIST = 'GET_BRANDED_HIERARCHY_LIST';

// Apr
export const GET_APR_LIST_ALL = 'GET_APR_LIST_ALL';
export const GET_SEGMENT_LIST_ALL = 'GET_SEGMENT_LIST_ALL';
export const GET_HIERARCHY_LIST_ALL = 'GET_HIERARCHY_LIST_ALL';

export const GET_DATA_CAPTURE_LIST_ALL = 'GET_DATA_CAPTURE_LIST_ALL';

export const GET_AUDIENCE_HIERARCHY_LIST_ALL = 'GET_AUDIENCE_HIERARCHY_LIST_ALL';


export const GET_PINCODE_LIST = 'GET_PINCODE_LIST';

// campaign site list
export const GET_CAMPAIGN_SITE_LIST_ALL = 'GET_CAMPAIGN_SITE_LIST_ALL'

export const GET_CAMPAIGN_PERFORMANCE_REPORT_TREND = 'GET_CAMPAIGN_PERFORMANCE_REPORT_TREND';

export const GET_CAMPAIGN_SEGMENT_REPORT_TREND = 'GET_CAMPAIGN_SEGMENT_REPORT_TREND';

export const GET_MEDIA_PLANNING_REPORT_TREND = 'GET_MEDIA_PLANNING_REPORT_TREND';
export const GET_PINCODE_LIST_ALL = 'GET_PINCODE_LIST_ALL'

export const GET_CAMPAIGN_PIXEL_LIST = 'GET_CAMPAIGN_PIXEL_LIST';
