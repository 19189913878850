import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import rootStore from './store/store';
import { CONTEXT } from './constants/configuration';
import App from './containers';
import ScrollToTop from './ScrollToTop';
import './assets/iconfonts/mdi/css/materialdesignicons.min.css';

const { store } = rootStore;
Loadable.preloadReady().then(() => {
  ReactDOM.render(<Provider store={store}>
    <Router>
      <ScrollToTop>
        <Route path={CONTEXT} component={App} />
      </ScrollToTop>
    </Router>
  </Provider>, document.getElementById('root'));
});
// ReactDOM.render(
//   <Provider store={store}>
//     <Router>
//       <ScrollToTop>
//         <Route path={CONTEXT} component={App} />
//       </ScrollToTop>
//     </Router>
//   </Provider>,
//   document.getElementById('root')
// );
