export const PERMISSION = {
  DASHBOARD: {
    BIDDER: {
      BIDDERREPORT: {
        VIEW: "DASHBOARD-BIDDER-VIEW-LOGIN :: DASHBOARD :: BIDDER :: BIDDER REPORT",
      },
      CAMPAIGNREPORT: {
        VIEW: "DASHBOARD-BIDDER-VIEW-LOGIN :: DASHBOARD :: BIDDER :: CAMPAIGN PERFORMANCE REPORT",
      },
      MEDIAPLANNIGREPORT: {
        VIEW: "DASHBOARD-BIDDER-VIEW-LOGIN :: DASHBOARD :: BIDDER :: MEDIA PLANING REPORT",
      },
    },
    DATAPARTNER: {
      ADMINREPORT: {
        VIEW: "DASHBOARD-DATA PARTNER REPORTS-VIEW-LOGIN :: DASHBOARD :: DATA PARTNER REPORTS :: ADMIN REPORT",
      },
      CAMPAIGNREPORT: {
        VIEW: "DASHBOARD-DATA PARTNER REPORTS-VIEW-LOGIN :: DASHBOARD :: DATA PARTNER REPORTS :: CAMPAIGN PERFORMANCE REPORT",
      },
      SEGMENTREPORT: {
        VIEW: "DASHBOARD-DATA PARTNER REPORTS-VIEW-LOGIN :: DASHBOARD :: DATA PARTNER REPORTS :: SEGMENT WISE REPORT",
      },
    },
    ION: {
      MEDIAREPORT: {
        VIEW: "DASHBOARD-ION-VIEW-LOGIN :: DASHBOARD :: ION :: MEDIA REPORT",
      },
      BILLINGREPORT: {
        VIEW: "DASHBOARD-ION-VIEW-LOGIN :: DASHBOARD :: ION :: BILLING REPORT",
      },
      QPSREPORT: {
        VIEW: "DASHBOARD-ION-VIEW-LOGIN :: DASHBOARD :: ION :: QPS REPORT",
      },
      IONREPORT:{
        VIEW: "DASHBOARD-ION-VIEW-LOGIN :: DASHBOARD :: ION :: ION REPORT",
      }
    },
    RECOENGINE: {
      STATS: {
        VIEW: "DASHBOARD-RECO ENGINE-VIEW-LOGIN :: DASHBOARD :: RECO ENGINE :: STATS",
      },
      DATACAPTURE: {
        VIEW: "DASHBOARD-RECO ENGINE-VIEW-LOGIN :: DASHBOARD :: RECO ENGINE :: DATA CAPTURE",
      },
      CONTENTINGESTION: {
        VIEW: "DASHBOARD-RECO ENGINE-VIEW-LOGIN :: DASHBOARD :: RECO ENGINE :: CONTENT INGESTION",
      }
    }
  },
  IO: {
    IO: {
      VIEW: "IO-IO-VIEW-LOGIN :: IO",
      ADD: "IO-IO-ADD-LOGIN :: IO :: ADD IO",
      EDIT: "IO-IO-EDIT-LOGIN :: IO :: EDIT IO",
      DELETE: "IO-IO-DELETE-LOGIN :: IO"
    }
  },
  CAMPAIGN: {
    CAMPAIGN: {
      VIEW: "CAMPAIGN-CAMPAIGN-VIEW-LOGIN :: CAMPAIGN",
      ADD: "CAMPAIGN-CAMPAIGN-ADD-LOGIN :: ADD CAMPAIGN",
      EDIT: "CAMPAIGN-CAMPAIGN-EDIT-LOGIN :: EDIT CAMPAIGN",
      DELETE: "CAMPAIGN-CAMPAIGN-DELETE-LOGIN :: CAMPAIGN"
    },
    SITELIST: {
      VIEW: "CAMPAIGN-SITE LIST-VIEW-LOGIN :: CAMPAIGN :: SITE LIST :: VIEW",
      ADD: "CAMPAIGN-SITE LIST-ADD-LOGIN :: CAMPAIGN :: SITE LIST :: ADD",
      EDIT: "CAMPAIGN-SITE LIST-EDIT-LOGIN :: CAMPAIGN :: SITE LIST :: EDIT",
      DELETE: "CAMPAIGN-SITE LIST-DELETE-LOGIN :: CAMPAIGN :: SITE LIST :: DELETE"
    },
    PRIVATEMARKETPLACE: {
      VIEW: "CAMPAIGN-PRIVATE MARKET PLACE-VIEW-LOGIN :: CAMPAIGN :: PRIVATE MARKET PLACE :: VIEW",
      ADD: "CAMPAIGN-PRIVATE MARKET PLACE-ADD-LOGIN :: CAMPAIGN :: PRIVATE MARKET PLACE :: ADD",
      EDIT: "CAMPAIGN-PRIVATE MARKET PLACE-EDIT-LOGIN :: CAMPAIGN :: PRIVATE MARKET PLACE :: EDIT",
      DELETE: "CAMPAIGN-PRIVATE MARKET PLACE-DELETE-LOGIN :: CAMPAIGN :: PRIVATE MARKET PLACE :: DELETE"
    }
  },
  CREATIVE: {
    DISPLAY: {
      VIEW: "CREATIVE-DISPLAY-VIEW-LOGIN :: CREATIVE :: DISPLAY",
      ADD: "CREATIVE-DISPLAY-ADD-LOGIN :: CREATIVE :: ADD CREATIVE DISPLAY",
      EDIT: "CREATIVE-DISPLAY-EDIT-LOGIN :: CREATIVE :: EDIT CREATIVE DISPLAY",
      DELETE: "CREATIVE-DISPLAY-DELETE-LOGIN :: CREATIVE :: DISPLAY"
    },
    VIDEO: {
      VIEW: "CREATIVE-VIDEO-VIEW-LOGIN :: CREATIVE :: VIDEO",
      ADD: "CREATIVE-VIDEO-ADD-LOGIN :: CREATIVE :: ADD CREATIVE VIDEO",
      EDIT: "CREATIVE-VIDEO-EDIT-LOGIN :: CREATIVE :: EDIT CREATIVE VIDEO",
      DELETE: "CREATIVE-VIDEO-DELETE-LOGIN :: CREATIVE :: VIDEO"
    },
    NATIVE: {
      VIEW: "CREATIVE-NATIVE-VIEW-LOGIN :: CREATIVE :: NATIVE",
      ADD: "CREATIVE-NATIVE-ADD-LOGIN :: CREATIVE :: ADD NATIVE",
      EDIT: "CREATIVE-NATIVE-EDIT-LOGIN :: CREATIVE :: EDIT NATIVE",
      DELETE: "CREATIVE-NATIVE-DELETE-LOGIN :: CREATIVE :: NATIVE"
    },
    AUDIO: {
      VIEW: "CREATIVE-AUDIO-VIEW-LOGIN :: CREATIVE :: AUDIO",
      ADD: "CREATIVE-AUDIO-ADD-LOGIN :: CREATIVE :: ADD CREATIVE AUDIO",
      EDIT: "CREATIVE-AUDIO-EDIT-LOGIN :: CREATIVE :: EDIT CREATIVE AUDIO",
      DELETE: "CREATIVE-AUDIO-DELETE-LOGIN :: CREATIVE :: AUDIO"
    },
  },
  EXCHANGE: {
    ADUNIT: {
      VIEW: "EXCHANGE-AD UNIT-VIEW-LOGIN :: EXCHANGE :: AD UNIT",
      ADD: "EXCHANGE-AD UNIT-ADD-LOGIN :: EXCHANGE :: ADD AD UNIT",
      EDIT: "EXCHANGE-AD UNIT-EDIT-LOGIN :: EXCHANGE :: EDIT AD UNIT",
      DELETE: "EXCHANGE-AD UNIT-DELETE-LOGIN :: EXCHANGE :: AD UNIT"
    },
    ADUNITGROUPS: {
      VIEW: "EXCHANGE-AD UNIT GROUPS-VIEW-LOGIN :: EXCHANGE :: AD UNIT GROUPS",
      ADD: "EXCHANGE-AD UNIT GROUPS-ADD-LOGIN :: EXCHANGE :: ADD AD UNIT GROUPS",
      EDIT: "EXCHANGE-AD UNIT GROUPS-EDIT-LOGIN :: EXCHANGE :: EDIT AD UNIT GROUPS",
      DELETE: "EXCHANGE-AD UNIT GROUPS-DELETE-LOGIN :: EXCHANGE :: AD UNIT GROUPS"
    },
    PRIVATEMARKETPLACE: {
      VIEW: "EXCHANGE-PRIVATE MARKET PLACE-VIEW-LOGIN :: EXCHANGE :: PRIVATE MARKET PLACE",
      ADD: "EXCHANGE-PRIVATE MARKET PLACE-ADD-LOGIN :: EXCHANGE :: PRIVATE MARKET PLACE :: ADD PRIVATE MARKET PLACE",
      EDIT: "EXCHANGE-PRIVATE MARKET PLACE-EDIT-LOGIN :: EXCHANGE :: PRIVATE MARKET PLACE :: EDIT PRIVATE MARKET PLACE",
      DELETE: "EXCHANGE-PRIVATE MARKET PLACE-DELETE-LOGIN :: EXCHANGE :: PRIVATE MARKET PLACE"
    },
    MEDIATIONADGROUP: {
      VIEW: "EXCHANGE-MEDIATION AD GROUP-VIEW-LOGIN :: EXCHANGE :: AD UNIT GROUPS",
      ADD: "EXCHANGE-MEDIATION AD GROUP-ADD-LOGIN :: EXCHANGE :: MEDIATION AD GROUP :: ADD MEDIATION AD GROUP",
      EDIT: "EXCHANGE-MEDIATION AD GROUP-EDIT-LOGIN :: EXCHANGE :: EDIT AD UNIT GROUPS",
      DELETE: "EXCHANGE-MEDIATION AD GROUP-DELETE-LOGIN :: EXCHANGE :: AD UNIT GROUPS"
    },
    MEDIATION: {
      VIEW: "EXCHANGE-MEDIATION-VIEW-LOGIN :: EXCHANGE :: MEDIATON",
      ADD: "EXCHANGE-MEDIATION-ADD-LOGIN :: EXCHANGE :: MEDIATON :: ADD MEDIATON",
      EDIT: "EXCHANGE-MEDIATION-EDIT-LOGIN :: EXCHANGE :: MEDIATION :: EDIT MEDIATON",
      DELETE: "EXCHANGE-MEDIATION-DELETE-LOGIN :: EXCHANGE :: MEDIATON"
    },
    TAGIDMAPPING: {
      VIEW: "EXCHANGE-TAGID MAPPING-VIEW-LOGIN :: EXCHANGE :: TAGID MAPPING :: LIST",
      ADD: "EXCHANGE-TAGID MAPPING-ADD-LOGIN :: EXCHANGE :: TAGID MAPPING :: ADD",
      EDIT: "EXCHANGE-TAGID MAPPING-EDIT-LOGIN :: EXCHANGE :: TAGID MAPPING :: LIST",
      DELETE: "EXCHANGE-TAGID MAPPING-DELETE-LOGIN :: EXCHANGE :: TAGID MAPPING :: LIST",
      LISTDOWNLOAD: "EXCHANGE-TAGID MAPPING-DOWNLOAD-LOGIN :: EXCHANGE :: TAGID MAPPING :: LIST",
      BULKUPLOADDOWNLOAD: "EXCHANGE-TAGID MAPPING-DOWNLOAD-LOGIN :: EXCHANGE :: TAGID MAPPING :: BULK UPLOAD",
      BULKUPLOADADD: "EXCHANGE-TAGID MAPPING-ADD-LOGIN :: EXCHANGE :: TAGID MAPPING :: BULK UPLOAD",
      BULKUPLOADLIST: "EXCHANGE-TAGID MAPPING-VIEW-LOGIN :: EXCHANGE :: TAGID MAPPING :: BULK UPLOAD"
    }
  },
  ADMIN: {
    TENANT: {
      VIEW: "ADMIN-TENANT-VIEW-LOGIN :: ADMIN :: TENANT",
      ADD: "ADMIN-TENANT-ADD-LOGIN :: ADMIN :: TENANT :: ADD TENANT",
      EDIT: "ADMIN-TENANT-EDIT-LOGIN :: ADMIN :: TENANT :: EDIT TENANT",
      DELETE: "ADMIN-TENANT-DELETE-LOGIN :: ADMIN :: TENANT",
    },
    MGRTENANT: {
      VIEW: "ADMIN-MGRTENANT-VIEW-LOGIN :: ADMIN ::VIEW MGR TENANT",
      // ADD: "ADMIN-TENANT-ADD-LOGIN :: ADMIN :: TENANT :: ADD TENANT",
      // EDIT: "ADMIN-TENANT-EDIT-LOGIN :: ADMIN :: TENANT :: EDIT TENANT",
      ADD: "ADMIN-MGRTENANT-ADD-LOGIN :: ADMIN :: MGR TENANT :: ADD MGR TENANT",
      EDIT:"ADMIN-MGRTENANT-EDIT-LOGIN :: ADMIN :: MGR TENANT :: EDIT MGR TENANT",
      // DELETE: "ADMIN-TENANT-DELETE-LOGIN :: ADMIN :: TENANT",

    },
    CURRENCYRATE:{
      VIEW: "ADMIN-CURRENCY RATE-VIEW-LOGIN :: ADMIN :: CURRENCY RATE",
      ADD: "ADMIN-CURRENCY RATE-ADD-LOGIN :: ADMIN :: CURRENCY RATE :: ADD CURRENCY RATE",
      EDIT: "ADMIN-CURRENCY RATE-EDIT-LOGIN :: ADMIN :: CURRENCY RATE :: EDIT CURRENCY RATE",
    },
    ACCOUNT: {
      VIEW: "ADMIN-ACCOUNT-VIEW-LOGIN :: ADMIN :: ACCOUNT",
      ADD: "ADMIN-ACCOUNT-ADD-LOGIN :: ADMIN :: ACCOUNT :: ADD ACCOUNT",
      EDIT: "ADMIN-ACCOUNT-EDIT-LOGIN :: ADMIN :: ACCOUNT :: EDIT ACCOUNT",
      DELETE: "ADMIN-ACCOUNT-DELETE-LOGIN :: ADMIN :: ACCOUNT"
    },
    FEATURES: {
      VIEW: "ADMIN-FEATURES-VIEW-LOGIN :: ADMIN :: FEATURES",
      ADD: "ADMIN-FEATURES-ADD-LOGIN :: ADMIN :: FEATURES",
      EDIT: "ADMIN-FEATURES-EDIT-LOGIN :: ADMIN :: FEATURES",
      DELETE: "ADMIN-FEATURES-DELETE-LOGIN :: ADMIN :: FEATURES"
    },
    USERS: {
      VIEW: "ADMIN-USERS-VIEW-LOGIN :: ADMIN :: USER",
      ADD: "ADMIN-USERS-ADD-LOGIN :: ADMIN :: USER :: ADD USER",
      EDIT: "ADMIN-USERS-EDIT-LOGIN :: ADMIN :: USER :: EDIT USER",
      DELETE: "ADMIN-USERS-DELETE-LOGIN :: ADMIN :: USER"
    },
    DEMANDPARTNER: {
      VIEW: "ADMIN-DEMAND PARTNER-VIEW-LOGIN :: ADMIN :: DEMAND PARTNER",
      ADD: "ADMIN-DEMAND PARTNER-ADD-LOGIN :: ADMIN :: DEMAND PARTNER :: ADMIN :: ADD DEMAND PARTNER",
      EDIT: "ADMIN-DEMAND PARTNER-EDIT-LOGIN :: ADMIN :: DEMAND PARTNER :: ADMIN :: EDIT DEMAND PARTNER",
      DELETE: "ADMIN-DEMAND PARTNER-DELETE-LOGIN :: ADMIN :: DEMAND PARTNER"
    },
    SUPPLYPARTNER: {
      VIEW: "ADMIN-SUPPLY PARTNER-VIEW-LOGIN :: ADMIN :: SUPPLY PARTNER",
      ADD: "ADMIN-SUPPLY PARTNER-ADD-LOGIN :: ADMIN :: SUPPLY PARTNER :: ADD SUPPLY PARTNER",
      EDIT: "ADMIN-SUPPLY PARTNER-EDIT-LOGIN :: ADMIN :: SUPPLY PARTNER :: EDIT SUPPLY PARTNER",
      DELETE: "ADMIN-SUPPLY PARTNER-DELETE-LOGIN :: ADMIN :: SUPPLY PARTNER"
    },
    VARIABLE: {
      VIEW: "ADMIN-VARIABLE-VIEW-LOGIN :: ADMIN :: VARIABLE",
      ADD: "ADMIN-VARIABLE-ADD-LOGIN :: ADMIN :: VARIABLE :: ADD VARIABLE",
      EDIT: "ADMIN-VARIABLE-EDIT-LOGIN :: ADMIN :: VARIABLE :: EDIT VARIABLE",
      DELETE: "ADMIN-VARIABLE-DELETE-LOGIN :: ADMIN :: VARIABLE"
    },
    ROLES: {
      VIEW: "ADMIN-ROLES-VIEW-LOGIN :: ADMIN :: ROLES",
      ADD: "ADMIN-ROLES-ADD-LOGIN :: ADMIN :: ROLES :: ADD ROLES",
      EDIT: "ADMIN-ROLES-EDIT-LOGIN :: ADMIN :: ROLE :: EDIT ROLE",
      DELETE: "ADMIN-ROLES-DELETE-LOGIN :: ADMIN :: ROLES"
    },
    AGREEMENT:{
      VIEW: "ADMIN-AGREEMENT-VIEW-LOGIN :: ADMIN :: AGREEMENT",
      ADD: "ADMIN-AGREEMENT-ADD-LOGIN :: ADMIN :: AGREEMENT :: ADD AGREEMENT",
      EDIT: "ADMIN-AGREEMENT-EDIT-LOGIN :: ADMIN :: AGREEMENT :: EDIT AGREEMENT",
    },
    SUPPLYPATH:{
      VIEW: "ADMIN-SUPPLY PATH-VIEW-LOGIN :: ADMIN :: SUPPLY PATH",
      EDIT: "ADMIN-SUPPLY PATH-EDIT-LOGIN :: ADMIN :: SUPPLY PATH :: EDIT SUPPLY PATH",
    }, PLATFORM: {
      VIEW: "ADMIN-PLATFORM-VIEW-LOGIN :: ADMIN :: PLATFORM",
      ADD: "ADMIN-PLATFORM-ADD-LOGIN :: ADMIN :: PLATFORM :: ADD PLATFORM",
      EDIT: "ADMIN-PLATFORM-EDIT-LOGIN :: ADMIN :: PLATFORM :: EDIT PLATFORM"
    }
  },

  AUDIENCE: {
    AUDIENCEHIERARCHY: {
      VIEW: "AUDIENCE-AUDIENCE HIERARCHY-VIEW-LOGIN :: AUDIENCE :: AUDIENCE HIERARCHY",
      EDIT: "AUDIENCE-AUDIENCE HIERARCHY-EDIT-LOGIN :: AUDIENCE :: AUDIENCE HIERARCHY"
    },
    HIERARCHYMANAGER: {
      VIEW: "AUDIENCE-HIERARCHY MANAGER-VIEW-LOGIN :: AUDIENCE :: HIERARCHY MANAGER",
      ADD: "AUDIENCE-HIERARCHY MANAGER-ADD-LOGIN :: AUDIENCE :: HIERARCHY MANAGER",
      EDIT: "AUDIENCE-HIERARCHY MANAGER-EDIT-LOGIN :: AUDIENCE :: HIERARCHY MANAGER",
      DELETE: "AUDIENCE-HIERARCHY MANAGER-DELETE-LOGIN :: AUDIENCE :: HIERARCHY MANAGER"
    },
    APR: {
      VIEW: "AUDIENCE-APR-VIEW-LOGIN :: AUDIENCE :: APR",
      ADD: "AUDIENCE-APR-ADD-LOGIN AUDIENCE :: APR AUDIENCE :: CREATE NEW APR",
      EDIT: "AUDIENCE-APR-EDIT-LOGIN AUDIENCE :: APR AUDIENCE :: EDIT  APR",
      DOWNLOAD: "AUDIENCE-APR-DOWNLOAD-LOGIN :: AUDIENCE :: APR"
    },
    TAXONOMYMANAGER: {
      VIEW: "AUDIENCE-TAXONOMY MANAGER-VIEW-LOGIN :: AUDIENCE :: SEGMENT",
      ADD: "AUDIENCE-TAXONOMY MANAGER-ADD-LOGIN :: AUDIENCE :: ADD SEGMENT",
      EDIT: "AUDIENCE-TAXONOMY MANAGER-EDIT-LOGIN :: AUDIENCE :: EDIT SEGMENT",
      DELETE: "AUDIENCE-TAXONOMY MANAGER-DELETE-LOGIN :: AUDIENCE :: SEGMENT"
    },
    DATACAPTURE: {
      VIEW: "AUDIENCE-DATA CAPTURE-VIEW-LOGIN :: AUDIENCE :: DATA CAPTURE",
      ADD: "AUDIENCE-DATA CAPTURE-ADD-LOGIN :: AUDIENCE :: DATA CAPTURE AUDIENCE :: ADD NEW CONVERSION TAG",
      EDIT: "AUDIENCE-DATA CAPTURE-EDIT-LOGIN AUDIENCE :: DATA CAPTURE AUDIENCE :: EDIT CONVERSION TAG",
      DELETE: "AUDIENCE-DATA CAPTURE-DELETE-LOGIN :: AUDIENCE :: DATA CAPTURE",
      DOWNLOAD: "AUDIENCE-DATA CAPTURE-DOWNLOAD-LOGIN :: AUDIENCE :: DATA CAPTURE"
    }
  }
}


export const MODULE_PERMISSIONS = {
  DASHBOARD: [
    PERMISSION.DASHBOARD.BIDDER.BIDDERREPORT.VIEW,
    PERMISSION.DASHBOARD.BIDDER.CAMPAIGNREPORT.VIEW,
    PERMISSION.DASHBOARD.BIDDER.MEDIAPLANNIGREPORT.VIEW,
    PERMISSION.DASHBOARD.ION.MEDIAREPORT.VIEW,
    PERMISSION.DASHBOARD.ION.BILLINGREPORT.VIEW,
    PERMISSION.DASHBOARD.ION.QPSREPORT.VIEW,
    PERMISSION.DASHBOARD.ION.IONREPORT.VIEW,
    PERMISSION.DASHBOARD.RECOENGINE.STATS.VIEW,
    PERMISSION.DASHBOARD.RECOENGINE.DATACAPTURE.VIEW,
    PERMISSION.DASHBOARD.RECOENGINE.CONTENTINGESTION.VIEW,
    PERMISSION.DASHBOARD.DATAPARTNER.ADMINREPORT.VIEW,
    PERMISSION.DASHBOARD.DATAPARTNER.CAMPAIGNREPORT.VIEW,
    PERMISSION.DASHBOARD.DATAPARTNER.SEGMENTREPORT.VIEW
  ],
  DASHBOARD_BIDDER: [
    PERMISSION.DASHBOARD.BIDDER.BIDDERREPORT.VIEW,
    PERMISSION.DASHBOARD.BIDDER.CAMPAIGNREPORT.VIEW,
    PERMISSION.DASHBOARD.BIDDER.MEDIAPLANNIGREPORT.VIEW
  ],
  DASHBOARD_DATA_PARTNER: [
    PERMISSION.DASHBOARD.DATAPARTNER.ADMINREPORT.VIEW,
    PERMISSION.DASHBOARD.DATAPARTNER.CAMPAIGNREPORT.VIEW,
    PERMISSION.DASHBOARD.DATAPARTNER.SEGMENTREPORT.VIEW
  ],
  DASHBOARD_ION: [
    PERMISSION.DASHBOARD.ION.MEDIAREPORT.VIEW,
    PERMISSION.DASHBOARD.ION.BILLINGREPORT.VIEW,
    PERMISSION.DASHBOARD.ION.QPSREPORT.VIEW,
    PERMISSION.DASHBOARD.ION.IONREPORT.VIEW
  ],
  DASHBOARD_RECOENGINE: [
    PERMISSION.DASHBOARD.RECOENGINE.STATS.VIEW,
    PERMISSION.DASHBOARD.RECOENGINE.DATACAPTURE.VIEW,
    PERMISSION.DASHBOARD.RECOENGINE.CONTENTINGESTION.VIEW
  ],
  IO: [
    PERMISSION.IO.IO.VIEW
  ],
  CAMPAIGN: [
    PERMISSION.CAMPAIGN.CAMPAIGN.VIEW
  ],

  CAMPAIGN_SITELIST :[PERMISSION.CAMPAIGN.SITELIST.VIEW],
  CAMPAIGN_PRIVATEMARKETPLACE :[PERMISSION.CAMPAIGN.PRIVATEMARKETPLACE.VIEW],

  CREATIVE: [
    PERMISSION.CREATIVE.DISPLAY.VIEW,
    PERMISSION.CREATIVE.VIDEO.VIEW,
    PERMISSION.CREATIVE.NATIVE.VIEW,
  ],
  CREATIVE_DISPLAY: [
    PERMISSION.CREATIVE.DISPLAY.VIEW
  ],
  CREATIVE_VIDEO: [
    PERMISSION.CREATIVE.VIDEO.VIEW,
  ],
  CREATIVE_NATIVE: [
    PERMISSION.CREATIVE.NATIVE.VIEW,
  ],
  CREATIVE_AUDIO:[
    PERMISSION.CREATIVE.AUDIO.VIEW,
  ],

  EXCHANGE: [
    PERMISSION.EXCHANGE.ADUNIT.VIEW,
    PERMISSION.EXCHANGE.ADUNITGROUPS.VIEW,
    PERMISSION.EXCHANGE.PRIVATEMARKETPLACE.VIEW,
    PERMISSION.EXCHANGE.MEDIATIONADGROUP.VIEW,
    PERMISSION.EXCHANGE.MEDIATION.VIEW,
    PERMISSION.EXCHANGE.TAGIDMAPPING.VIEW,
    PERMISSION.EXCHANGE.TAGIDMAPPING.BULKUPLOADLIST
  ],

  EXCHANGE_ADUNIT: [
    PERMISSION.EXCHANGE.ADUNIT.VIEW
  ],
  EXCHANGE_ADUNITGROUPS: [
    PERMISSION.EXCHANGE.ADUNITGROUPS.VIEW
  ],
  EXCHANGE_PRIVATEMARKETPLACE: [
    PERMISSION.EXCHANGE.PRIVATEMARKETPLACE.VIEW
  ],
  EXCHANGE_MEDIATIONADGROUP: [
    PERMISSION.EXCHANGE.MEDIATIONADGROUP.VIEW
  ],
  EXCHANGE_MEDIATION: [
    PERMISSION.EXCHANGE.MEDIATION.VIEW
  ],
  EXCHANGE_TAGIDMAPPING: [
    PERMISSION.EXCHANGE.TAGIDMAPPING.VIEW
  ],

  ADMIN: [
    PERMISSION.ADMIN.TENANT.ADD,
    PERMISSION.ADMIN.MGRTENANT.ADD,
    PERMISSION.ADMIN.ACCOUNT.VIEW,
    PERMISSION.ADMIN.FEATURES.VIEW,
    PERMISSION.ADMIN.USERS.VIEW,
    PERMISSION.ADMIN.DEMANDPARTNER.VIEW,
    PERMISSION.ADMIN.SUPPLYPARTNER.VIEW,
    PERMISSION.ADMIN.VARIABLE.VIEW,
    PERMISSION.ADMIN.ROLES.VIEW,
    PERMISSION.ADMIN.AGREEMENT.VIEW,
    PERMISSION.ADMIN.SUPPLYPATH.VIEW
  ],

  ADMIN_TENANT: [PERMISSION.ADMIN.TENANT.VIEW],
  ADMIN_MGRTENANT: [PERMISSION.ADMIN.MGRTENANT.ADD],
  ADMIN_ACCOUNT:[PERMISSION.ADMIN.ACCOUNT.VIEW],
  ADMIN_FEATURES: [PERMISSION.ADMIN.FEATURES.VIEW],
  ADMIN_USERS: [PERMISSION.ADMIN.USERS.VIEW],
  ADMIN_DEMANDPARTNER: [PERMISSION.ADMIN.DEMANDPARTNER.VIEW],
  ADMIN_SUPPLYPARTNER: [PERMISSION.ADMIN.SUPPLYPARTNER.VIEW],
  ADMIN_VARIABLE: [PERMISSION.ADMIN.VARIABLE.VIEW],
  ADMIN_ROLES: [PERMISSION.ADMIN.ROLES.VIEW],
  ADMIN_AGREEMENT: [PERMISSION.ADMIN.AGREEMENT.VIEW],
  ADMIN_SUPPLYPATH: [PERMISSION.ADMIN.SUPPLYPATH.VIEW],
  ADMIN_PLATFORM:[PERMISSION.ADMIN.PLATFORM.VIEW],

  AUDIENCE: [
    PERMISSION.AUDIENCE.AUDIENCEHIERARCHY.VIEW,
    PERMISSION.AUDIENCE.HIERARCHYMANAGER.VIEW,
    PERMISSION.AUDIENCE.APR.VIEW,
    PERMISSION.AUDIENCE.TAXONOMYMANAGER.VIEW,
    PERMISSION.AUDIENCE.DATACAPTURE.VIEW,
  ],

  AUDIENCE_HIERARCHY: [PERMISSION.AUDIENCE.AUDIENCEHIERARCHY.VIEW],
  AUDIENCE_HIERARCHYMANAGER: [PERMISSION.AUDIENCE.HIERARCHYMANAGER.VIEW],
  AUDIENCE_APR: [PERMISSION.AUDIENCE.APR.VIEW],
  AUDIENCE_TAXONOMYMANAGER: [PERMISSION.AUDIENCE.TAXONOMYMANAGER.VIEW],
  AUDIENCE_DATACAPTURE: [PERMISSION.AUDIENCE.DATACAPTURE.VIEW],

}