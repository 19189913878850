// // index reducer, one reducer to rule them all

// import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'

// import app from './app.reducer';

// const appPersistConfig = {
//   key: 'app',
//   storage: storage,
//   whitelist: ['configurationData']
//   // blacklist:['getStateList','getCityList','getStateAllList','getCityAllList','']
// }
 
// const rootReducer = combineReducers({
//   app : persistReducer(appPersistConfig, app),
// });

// export default rootReducer;

// index reducer, one reducer to rule them all

import { combineReducers } from 'redux';

import app from './app.reducer';

const rootReducer = combineReducers({
  app
});

export default rootReducer;