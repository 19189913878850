// Lodash Imports

import { isEqual, get, groupBy, uniq } from 'lodash';

import { MODULE_PERMISSIONS } from '../constants/permission';
import { ROUTE } from '../constants/route';
import CryptoJS from 'crypto-js';

// Action Type Imports

import {
    BEGIN_AJAX_CALL,
    END_AJAX_CALL,
    PRE_SIGN_IN_SUCCESS,
    SIGN_IN_SUCCESS,
    DISPATCH_PRE_SIGNIN_STATE_SUCCESS,
    GET_ADD_UNIT_LIST,
    GET_ADD_UNIT_GROUP_LIST,
    GET_PMP_LIST,
    GET_TAGLD_MAPPING_LIST,
    GET_TAGLD_MAPPING_ADUNIT_ALL_LIST,
    GET_TAGLD_MAPPING_BULK_LIST,
    GET_CONFIGURATION_DATA,
    GET_FILTER_LIST,
    GET_ION_FILTER_LIST,
    GET_MEDIA_REPORT,
    GET_ION_EXCHANGE_REPORT,
    GET_QPS_REPORT,
    GET_REVENUE_REPORT,
    GET_BIDDER_REPORT,
    GET_MEDIA_PLANNING_REPORT,
    GET_CAMPAIGN_PERFORMANCE_REPORT,
    GET_CAMPAIGN_LIST_ALL_REPORT,
    GET_CAMPAIGN_CATEGORY_TYPE_LIST,
    GET_IO_LIST_ALL_REPORT,
    GET_COUNTRY_LIST,
    GET_STATE_LIST,
    GET_CITY_LIST,
    GET_CAMPAIGN_SUB_CATEGORY,
    GET_ADVERTISER_CREATIVE_GROUP,
    GET_IO_ADVERTISER_LIST_REPORT,
    GET_TENANT_LIST_ALL,
    GET_PLATFORM_LIST_ALL,
    GET_DEMAND_PARTNER_LIST_ALL,
    GET_DEMAND_PARTNER_SEAT_LIST_ALL,
    GET_MEDIATION_DEMAND_PARTNER_SEAT_LIST_ALL,
    GET_TENANT_DEMAND_PARTNER_LIST,
    GET_SUPPLY_PARTNER_LIST_ALL,
    GET_ADMIN_SUPPLY_PARTNER_LIST_ALL,
    GET_TENANT_SUPPLY_PARTNER_LIST_ALL,
    GET_ADMIN_ION_SUPPLY_PARTNER_LIST_ALL,
    GET_ADMIN_PVT_SUPPLY_PARTNER_LIST_ALL,
    GET_LIST_FEATURE_ALL,
    GET_FEATURE_LIST_ALL,
    GET_MEDIATION_LIST,
    GET_MEDIATION_AD_GROUP_LIST,
    GET_STATE_LIST_ALL,
    GET_CITY_LIST_ALL,
    GET_PRIVATE_MARKET_PLACE_LIST,
    GET_EXCHANGE_LIST_REPORT,
    GET_ADMIN_VARIABLE_LIST_ALL,
    GET_AGREEMENT_LIST_ALL,
    GET_AGREEMENT_CONNECTION_LIST,
    GET_AGREEMENT_ACTIVE_SEAT_LIST,
    GET_AGREEMENT_PARENT_TENANT_LIST,
    GET_PVT_EXCHANGE_TENANT_LIST,
    GET_PVT_DEMAND_PARTNER_LIST,
    GET_ADMIN_USER_LIST_ALL,
    GET_ADMIN_ROLES_LIST_ALL,
    GET_ADMIN_MAIN_ROLES_LIST_ALL,
    GET_DEMAND_PARTNER_NAME_LIST_ALL,
    GET_USER_PERMISSION,
    GET_APR_LIST_ALL,
    GET_SEGMENT_LIST_ALL,
    GET_HIERARCHY_LIST_ALL,
    GET_DATA_CAPTURE_LIST_ALL,
    GET_BRANDED_HIERARCHY_LIST,
    GET_AUDIENCE_HIERARCHY_LIST_ALL,
    GET_PINCODE_LIST,
    GET_CAMPAIGN_SITE_LIST_ALL,
    GET_CAMPAIGN_PERFORMANCE_REPORT_TREND,
    GET_MEDIA_PLANNING_REPORT_TREND,
    GET_PINCODE_LIST_ALL,
    GET_CAMPAIGN_PIXEL_LIST,
    GET_CAMPAIGN_PERFORMANCE_REPORT_FILTER_LIST,
    GET_SAVE_REPORT,
    GET_SAVE_REPORT_DOWNLOAD,
    GET_MEDIA_SAVE_REPORT,
    GET_GENERATE_REPORT,
    GET_REFRESH_REPORT,
    GET_ACCOUNTS_LIST,
    GET_ROADBLOCK_LIST,
    ADD_CURRENCY_RATE,
    GET_CURRENCY_RATE_LIST,
    GET_USER_REACH_REPORT,
    ADD_TENANT,
    GET_NEW_SEGMENT_LIST_ALL,
    END_AJAX_CALL_CONFIG,
    BEGIN_AJAX_CALL_CONFIG,
    GET_CAMPAIGN_SEGMENT_REPORT_TREND,
    GET_DATA_PARTNER_CUSTOM_FILTER_LIST
} from '../constants/actionType';

// Api Import
import {
    getPreSignInData,
    getSignInData,
    getReSignInData,
    logoutApi,
    getConfigurationData,
    getFilterListApi,
    getIonFilterListApi,
    getAdUnitListApi,
    editAdUnitApi,
    getAddUnitSearchApi,
    addAdUnitApi,
    addBulkAdUnitApi,
    deleteAdUnitApi,
    downloadAddUnitApi,
    getAdUnitGroupListApi,
    getAdminSupplypartnerListApi,
    getAdminTenantSupplypartnerListApi,
    deleteAdUnitGroupApi,
    addAdUnitGroupApi,
    addBulkAdUnitGroupApi,
    getPMPListApi,
    getPMPListFilterApi,
    addPMPApi,
    deletePMPApi,
    getMediaReportApi,
    getIonExchangeApi,
    getQpsReportApi,
    getRevenueReportApi,
    getBidderReportApi,
    getMediaPlanningReportApi,
    getDataPartnerDailyReportApi,
    getCampaignPerformanceReportApi,
    getCampaignPerformanceReportDownloadApi,
    getIonExchangeDownloadApi,
    getCampaignListAllReportApi,
    getCampaignEditIdReportApi,
    getCampaignCategoryTypeApi,
    getStrategyBudgetDataApi,
    getStrategyBudgetSpendDataApi,
    getDayPartDataForTrobleShooter,
    getIoListAllReportApi,
    addIoReportApi,
    deleteIoApi,
    getCountryListApi,
    getStateListApi,
    getCityListApi,
    getCampaignSubCategoryReportApi,
    getAdvertiserCreativeGroupApi,
    getIoAdvertisersReportApi,
    addCampaignApi,
    addCreativeApi,
    getPartnerTenantDetailsApi,
    deleteCampaignApi,
    getIoEditIdReportApi,
    deleteCreativeApi,
    editCreativeApi,
    uploadFileActionApi,
    uploadSupplyPartnerFileActionApi,
    getDownloadDomainListApi,
    getTenantApi,
    addTenantApi,
    getSupplyPartnerApi,
    addSupplyPartnerApi,
    deleteSupplyPartnerApi,
    editSupplyPartnerApi,
    getDemandpartnerListApi,
    getDemandpartnerSeatListApi,
    getMediationDemandpartnerSeatListApi,
    getTenantDemandpartnerListApi,
    addAdminDemandPartnerApi,
    editTenantApi,
    deleteTenantApi,
    getListFeatureApi,
    getFeatureListApi,
    addFeatureApi,
    addFeatureAttributeApi,
    getMediationApi,
    addMediationApi,
    deleteMediationApi,
    getTagldMappingListApi,
    getDataPartnerCampaignReportApi,
    getTagldMappingDownloadCSVApi,
    getTagldMappingListadunitAllApi,
    getSegmentSearchApi,
    tagldMappingAddAdunitmapApi,
    uploadSiteFileBulkCSVApi,
    tagldMappingBulkDownloadApi,
    gettagldMappingBulkListApi,
    getMediationAdGroupListApi,
    getMediationSearchApi,
    addMediationAdGroupListApi,
    deleteMediationAdGroupApi,
    getPrivateMarketPlaceApi,
    addPrivateMarketPlaceApi,
    deletePrivateMarketPlaceApi,
    getAdminVariableListApi,
    addAdminVariableApi,
    getAdminUserListApi,
    getAdminUserSingleListApi,
    addAdminUserApi,
    getRolesListApi,
    postFilterRolesListApi,
    getAdminRoleSingleListApi,
    addAdminRolesApi,
    getAgreementListApi,
    getAgreementConnectionListApi,
    getAgreementActiveSeatListApi,
    getPvtExchangeTenantListApi,
    getPvtDemandPartnerListApi,
    addAdminAgreementApi,
    getIONSupplypathListApi,
    updateIONSupplypathApi,
    updatePvtSupplypathApi,
    getPVTSupplypathListApi,
    getDemandPartnerNamesFilterApi,
    roleAccessPermissions,
    getAprApi,
    getSegmentListApi,
    getHierarchyListApi,
    getHierarchyDisplayListApi,
    postCampaignHierarchyListApi,
    addAprApi,
    getAprEditApi,
    aprDownloadApi,
    addHierarchyApi,
    deleteHierarchyApi,
    addSegmentApi,
    getDataPixelEditApi,
    addDataCaptureApi,
    getDataCaptureApi,
    getDataCaptureListApi,
    deleteDataCaptureApi,
    getsegmentEditApi,
    getAudhierarchyListApi,
    downloadingConversionTagApi,
    getBrandedHierarchyListApi,
    searchAudienceSegmentApi,
    getAudiancePixelMicrosApi,
    checkTagAndPostbackuniqueApi,
    uploadScriptApi,
    getPinCodeListApi,
    forgotPasswordApi,
    validateResetPasswordApi,
    changePasswordApi,
    getCampaignSiteApi,
    uploadSiteFileApi,
    addCampaignSiteApi,
    deleteCampaignSiteApi,
    getBidderReportDownloadApi,
    getMediaPlanningDownloadReportApi,
    getMediaReportDownloadApi,
    getRevenueReportDownloadApi,
    getQpsReportDownloadApi,
    getCampaignPixelListApi,
    deleteSegmentApi,
    deleteTagMapApi,
    addRoadblock,
    getRoadblockListApi,
    getCampaignPerformanceReportFilterListApi,
    getSaveReportApi,
    getHierarchyListCloneByIdApi,
    postHierarchySegmentUpdateApi,
    postHierarchySegmentDeleteApi,
    postHierarchySegmentRecallApi,
    getSaveReportDownloadApi,
    getMediaSaveReportApi,
    getGenerateReportApi,
    addSaveReportApi,
    refreshSaveReportApi,
    getRefreshReportApi,
    getAccountsListApi,
    addAccountApi,
    editAccountApi,
    addPlatformApi,
    editPlatformApi,
    getPlatformListApi,
    getCurrencyRateListApi,
    getHierarchyManagerCustomListApi,
    addCurrencyRateApi,
    getPlatformSingleListApi,
    getUserReachReportApi,
    getDataPartnerSegementReportApi,
    getUserReachReportDownloadApi,
    editUpTenantApi,
    addUpTenantApi,
    uploadPlatformFileActionApi,
    vastValidatorApi,
    getNewSegmentListApi,
    bulkPincodeUploadCampaign,
    audienceHierarchyDownloadCSVApi,
    taxonomyManagerTitleSearchApi,
    taxonomyManagerBulkIdSearchApi,
    SaveMediaReportApi,
    getAlertListApi,
    getAlertDeleteApi,
    siteListCSVDataApi,
    getUsersListApi,
    userAdminStatusApi,
    uploadPlatformConfigurationFileActionApi,
    postCuratedApi,
    getCuratedApi,
    getAdminReportDataApi,
    getDataPartnerCustomFilterApi,
    getAdminVariableListTypeApi
} from '../api';

export const beginAjaxCallAction = () => async dispatch => {
    dispatch({
        type: BEGIN_AJAX_CALL,
        showSpinner: true,
    });
};

export const endAjaxCallAction = () => async dispatch => {
    dispatch({
        type: END_AJAX_CALL,
        showSpinner: false,
    });
};

// export const getConfigurationDataAction = (attributesObj) => async dispatch => {
//     let configurationData = {};
//     try {
//         dispatch({
//             type: BEGIN_AJAX_CALL,
//             showSpinner: true,
//         });
//         // configurationData = await getConfigurationData(attributesObj);
//         configurationData = await getAdminVariableListApi(attributesObj);
//         dispatch({
//             type: GET_CONFIGURATION_DATA,
//             configurationData,
//         });
//     } catch (err) {
//     } finally {
//         dispatch({
//             type: END_AJAX_CALL,
//             showSpinner: false,
//         });
//     }
// };

export const getNewSegmentListAction = ({ tenantId, authToken, userId, hierarchyVar, parentVar, grandVar, type }) => async dispatch => {
    let getNewSegmentList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getNewSegmentList = await getNewSegmentListApi({ tenantId, authToken, userId, hierarchyVar, parentVar, grandVar, type });
        dispatch({
            type: GET_NEW_SEGMENT_LIST_ALL,
            getNewSegmentList,
        });
        return getNewSegmentList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getFilterListAction = (tenantId, authToken, userId) => async dispatch => {
    let filterList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        filterList = await getFilterListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_FILTER_LIST,
            filterList,
        });
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getIonFilterListAction = (props) => async dispatch => {
    let ionFilterList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ionFilterList = await getIonFilterListApi(props);
        dispatch({
            type: GET_ION_FILTER_LIST,
            ionFilterList,
        });
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};




export const dispatchAuthenticationDataAction = ({ preSignInState, signInData, preSignInData }) => async dispatch => {
    dispatch({
        type: DISPATCH_PRE_SIGNIN_STATE_SUCCESS,
        preSignInState,
    });
    dispatch({
        type: PRE_SIGN_IN_SUCCESS,
        preSignInData,
    });
    dispatch({
        type: SIGN_IN_SUCCESS,
        signInData,
    });
};

export const dispatchPreSignInData = preSignInState => async dispatch => {
    dispatch({
        type: DISPATCH_PRE_SIGNIN_STATE_SUCCESS,
        preSignInState,
    });
    localStorage.setItem('PreSignInState', JSON.stringify(preSignInState));
};

export const clearPreSignInData = () => async dispatch => {
    dispatch({
        type: DISPATCH_PRE_SIGNIN_STATE_SUCCESS,
        preSignInState: {},
    });
    dispatch({
        type: PRE_SIGN_IN_SUCCESS,
        preSignInData: {},
    });
    localStorage.setItem('PreSignInData', JSON.stringify({}));
};

export const getPreSignInDataAction = preSignInReqBody => async dispatch => {
    let preSignInData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        preSignInData = await getPreSignInData(preSignInReqBody);
        dispatch({
            type: PRE_SIGN_IN_SUCCESS,
            preSignInData,
        });
        localStorage.setItem('PreSignInData', JSON.stringify(preSignInData));
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getSignInDataAction = signInReqBody => async dispatch => {
    let signInData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        signInData = await getSignInData(signInReqBody);
        if (isEqual(get(signInData, 'responseCode.status'), 'Success')) {
            dispatch({
                type: SIGN_IN_SUCCESS,
                signInData,
            });
            localStorage.setItem('AuthData', JSON.stringify(signInData));

            let preSignInData = JSON.parse(localStorage.getItem('PreSignInData'));
            let preSignInState = JSON.parse(localStorage.getItem('PreSignInState'));
            let getUserPermissionList = {};
            let data = await roleAccessPermissions({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId });
            let permissionsList = [];
            data.responseList.forEach((a) => {
                if (a.roleDetails && a.roleDetails.length) {
                    a.roleDetails.forEach((b) => {
                        permissionsList.push((`${b.moduleName}-${b.featureName}-${b.useCaseName}-${b.bcrumName}`.toUpperCase()).trim());
                    });
                }
            });
            getUserPermissionList = uniq(permissionsList);
            let defaultMainRoute = ''
            // default route dashboard
            let defaultRoute = {};
            let dashboard = MODULE_PERMISSIONS.DASHBOARD.find(a => getUserPermissionList.indexOf(a) > -1);
            if (dashboard && ROUTE[dashboard]) {
                defaultRoute.dashboard = ROUTE[dashboard];
                defaultMainRoute = ROUTE[dashboard];
            }

            // default route io
            let io = MODULE_PERMISSIONS.IO.find(a => getUserPermissionList.indexOf(a) > -1);
            if (io && ROUTE[io]) {
                defaultRoute.io = ROUTE[io];
                if (!defaultMainRoute) {
                    defaultMainRoute = ROUTE[io];
                }
            }

            // default route campaign
            let campaign = MODULE_PERMISSIONS.CAMPAIGN.find(a => getUserPermissionList.indexOf(a) > -1);
            if (campaign && ROUTE[campaign]) {
                defaultRoute.campaign = ROUTE[campaign];
                if (!defaultMainRoute) {
                    defaultMainRoute = ROUTE[campaign];
                }
            }

            // default route creative
            let creative = MODULE_PERMISSIONS.CREATIVE.find(a => getUserPermissionList.indexOf(a) > -1);
            if (creative && ROUTE[creative]) {
                defaultRoute.creative = ROUTE[creative];
                if (!defaultMainRoute) {
                    defaultMainRoute = ROUTE[creative];
                }
            }

            // default route admin
            let admin = MODULE_PERMISSIONS.ADMIN.find(a => getUserPermissionList.indexOf(a) > -1);
            if (admin && ROUTE[admin]) {
                defaultRoute.admin = ROUTE[admin];
                if (!defaultMainRoute) {
                    defaultMainRoute = ROUTE[admin];
                }
            }

            // default route audience
            let audience = MODULE_PERMISSIONS.AUDIENCE.find(a => getUserPermissionList.indexOf(a) > -1);
            if (audience && ROUTE[audience]) {
                defaultRoute.audience = ROUTE[audience];
                if (!defaultMainRoute) {
                    defaultMainRoute = ROUTE[audience];
                }
            }

            // default route exchange
            let exchange = MODULE_PERMISSIONS.EXCHANGE.find(a => getUserPermissionList.indexOf(a) > -1);
            if (exchange && ROUTE[exchange]) {
                defaultRoute.exchange = ROUTE[exchange];
                if (!defaultMainRoute) {
                    defaultMainRoute = ROUTE[exchange];
                }
            }

            localStorage.setItem('getUserPermissionList', JSON.stringify(getUserPermissionList));
            localStorage.setItem('defaultRoute', JSON.stringify(defaultRoute));
            localStorage.setItem('defaultMainRoute', JSON.stringify(defaultMainRoute));

            dispatch({
                type: GET_USER_PERMISSION,
                getUserPermissionList,
                defaultRoute,
                defaultMainRoute
            });
            dispatch(await getRoleAccessPermissions({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId }));
            dispatch(getConfigurationDataAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId }));
            dispatch(getCountryAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId }));
            dispatch(getStateAllAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId }));
            // dispatch(getCityAllAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId }));
            // dispatch(getPincodeAllAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId }));
            return signInData;
        }
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getReSignInDataAction = (reSignInReqBody, authToken) => async dispatch => {
    let reSignInData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        reSignInData = await getReSignInData({ reSignInReqBody, authToken });
        if (isEqual(get(reSignInData, 'responseCode.status'), 'Success')) {
            dispatch({
                type: SIGN_IN_SUCCESS,
                signInData: reSignInData,
            });
            localStorage.setItem('AuthData', JSON.stringify(reSignInData));
            return reSignInData;
        }
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// getPartnerTenantDetailsApi
export const getPartnerTenantDetailsAction = (demandObj) => async dispatch => {
    console.log("HERE");
    let currencyRateList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        currencyRateList = await getPartnerTenantDetailsApi(demandObj);
        console.log(currencyRateList, "ION TENNAT DETAILS")
        // dispatch({
        //     // type: GET_CURRENCY_RATE_LIST,
        //     currencyRateList,
        // });
        return currencyRateList;
    } catch (err) {
        console.log(err);
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const logoutAction = (logoutReqBody, authToken) => async dispatch => {
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        await logoutApi({ logoutReqBody, authToken });
        dispatch({
            type: SIGN_IN_SUCCESS,
            signInData: {},
        });
        dispatch({
            type: PRE_SIGN_IN_SUCCESS,
            preSignInData: {},
        });
        dispatch({
            type: DISPATCH_PRE_SIGNIN_STATE_SUCCESS,
            preSignInState: {},
        });
        dispatch({
            type: GET_CONFIGURATION_DATA,
            configurationData: {},
        });
        localStorage.setItem('AuthData', JSON.stringify({}));
        localStorage.setItem('PreSignInData', JSON.stringify({}));
        localStorage.setItem('PreSignInState', JSON.stringify({}));
        localStorage.setItem('defaultRoute', JSON.stringify([]));
        localStorage.setItem('defaultMainRoute', JSON.stringify([]));
        localStorage.setItem('getUserPermissionList', JSON.stringify([]));
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// ---* ION * ---

// * addUnit *
// export const getAddUnitListAction = (tenantId, authToken, userId) => async dispatch => {
//     let adUnitList = {};
//     try {
//         dispatch({
//             type: BEGIN_AJAX_CALL,
//             showSpinner: true,
//         });
//         adUnitList = await getAdUnitListApi({ tenantId, authToken, userId });
//         dispatch({
//             type: GET_ADD_UNIT_LIST,
//             adUnitList,
//         });
//         return adUnitList;
//     } catch (err) {
//     } finally {
//         dispatch({
//             type: END_AJAX_CALL,
//             showSpinner: false,
//         });
//     }
// };

export const getAddUnitListAction = (tenantId, authToken, userId, tenantList, params = {}) => async dispatch => {
    let adUnitList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adUnitList = await getAdUnitListApi({ tenantId, authToken, userId, tenantList, params });
        dispatch({
            type: GET_ADD_UNIT_LIST,
            adUnitList,
        });
        return adUnitList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const editAdUnitAction = (tenantId, authToken, userId, editId) => async dispatch => {
    let editAdUnit = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        editAdUnit = await editAdUnitApi({ tenantId, authToken, userId, editId });
        return editAdUnit
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getAddUnitSearchAction = (tenantId, authToken, userId, params = {}) => async dispatch => {
    let adUnitGrouupSearchList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adUnitGrouupSearchList = await getAddUnitSearchApi({ tenantId, authToken, userId, params });
        return adUnitGrouupSearchList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const addAdUnitAction = (tenantId, authToken, userId, adUnitReqBody, addParams) => async dispatch => {
    let addAdUnitData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addAdUnitData = await addAdUnitApi({ tenantId, authToken, userId, adUnitReqBody, addParams });
        return addAdUnitData && addAdUnitData.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const addBulkAdUnitAction = (tenantId, authToken, userId, adUnitReqBody, addParams) => async dispatch => {
    let addAdUnitData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addAdUnitData = await addBulkAdUnitApi({ tenantId, authToken, userId, adUnitReqBody, addParams });
        return addAdUnitData
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const deleteAdUnitAction = (AdUnitObj) => async dispatch => {
    let deleteAdUnitData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteAdUnitData = await deleteAdUnitApi(AdUnitObj);
        return deleteAdUnitData
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const downloadAddUnitListAction = (AdUnitObj) => async dispatch => {
    let deleteAdUnitData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteAdUnitData = await downloadAddUnitApi(AdUnitObj);
        return deleteAdUnitData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// * AdUnit Group *
export const getAddUnitGroupListAction = (tenantId, authToken, userId) => async dispatch => {
    let adUnitGroupList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adUnitGroupList = await getAdUnitGroupListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_ADD_UNIT_GROUP_LIST,
            adUnitGroupList,
        });
        return adUnitGroupList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteAdUnitGroupAction = (AdUnitGroupObj) => async dispatch => {
    let deleteAdUnitGroup = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteAdUnitGroup = await deleteAdUnitGroupApi(AdUnitGroupObj);
        return deleteAdUnitGroup;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const addAdUnitGroupAction = (tenantId, authToken, userId, payload, addParams) => async dispatch => {
    let addAdUnitGruopData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addAdUnitGruopData = await addAdUnitGroupApi({ tenantId, authToken, userId, payload, addParams });
        return addAdUnitGruopData && addAdUnitGruopData.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addBulkAdUnitGroupAction = (tenantId, authToken, userId, FilePayload, addParams) => async dispatch => {
    let addBulkAdUnitGruopData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addBulkAdUnitGruopData = await addBulkAdUnitGroupApi({ tenantId, authToken, userId, FilePayload, addParams });
        return addBulkAdUnitGruopData
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
// * Private Market Place *
export const getPMPListAction = (tenantId, authToken, userId, param, Addparams) => async dispatch => {
    let pmpList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        pmpList = await getPMPListApi({ tenantId, authToken, userId, param, Addparams });
        dispatch({
            type: GET_PMP_LIST,
            pmpList,
        });
        return pmpList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// * Private Market Place Filter *
export const getPMPListFilterAction = (tenantId, authToken, userId, Addparams, payload) => async dispatch => {
    let pmpList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        pmpList = await getPMPListFilterApi({ tenantId, authToken, userId, Addparams, payload });
        dispatch({
            type: GET_PMP_LIST,
            pmpList,
        });
        return pmpList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getRoadblockList = (tenantId, authToken, userId) => async dispatch => {
    let roadblockList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        roadblockList = await getRoadblockListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_ROADBLOCK_LIST,
            roadblockList,
        });
        return roadblockList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addPMPAction = (addPMPObj) => async dispatch => {
    let addPMP = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addPMP = await addPMPApi(addPMPObj);
        return addPMP && addPMP.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addRoadblockAction = (addRoadblockObj) => async dispatch => {
    let addRoadblockRes = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addRoadblockRes = await addRoadblock(addRoadblockObj);
        return addRoadblockRes && addRoadblockRes.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deletePMPAction = (PMPObj) => async dispatch => {
    let deletePMP = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deletePMP = await deletePMPApi(PMPObj);
        return deletePMP;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// Add Mediation AdGroup

export const getMediationAdGroupListAction = (mediationAdGroupObj) => async dispatch => {
    let mediationAdGroupList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        mediationAdGroupList = await getMediationAdGroupListApi(mediationAdGroupObj);
        dispatch({
            type: GET_MEDIATION_AD_GROUP_LIST,
            mediationAdGroupList,
        });
        return mediationAdGroupList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getMediationSearchAction = (tenantId, authToken, userId, params = {}) => async dispatch => {
    let adUnitList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adUnitList = await getMediationSearchApi({ tenantId, authToken, userId, params });
        return adUnitList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const addMediationAdGroupAction = (mediationAdGroupObj) => async dispatch => {
    let addMediationAdGroup = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addMediationAdGroup = await addMediationAdGroupListApi(mediationAdGroupObj);
        return addMediationAdGroup;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteMediationAdGroupAction = (mediationAdGroupObj) => async dispatch => {
    let deleteMediationAdGroup = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteMediationAdGroup = await deleteMediationAdGroupApi(mediationAdGroupObj);
        return deleteMediationAdGroup;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// * mediation api *

export const getMediationListAction = (mediationObj) => async dispatch => {
    let mediationList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        mediationList = await getMediationApi(mediationObj);
        dispatch({
            type: GET_MEDIATION_LIST,
            mediationList,
        });
        return mediationList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addMediationAction = (mediationObj) => async dispatch => {
    let addMediation = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addMediation = await addMediationApi(mediationObj);
        return addMediation;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteMediationAction = (mediationObj) => async dispatch => {
    let deleteMediation = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteMediation = await deleteMediationApi(mediationObj);
        return deleteMediation;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// * TagldMapping *
export const getTagldMappingListAction = (tagldMappingObj) => async dispatch => {
    let tagldMappingList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        tagldMappingList = await getTagldMappingListApi(tagldMappingObj);
        dispatch({
            type: GET_TAGLD_MAPPING_LIST,
            tagldMappingList,
        });
        return tagldMappingList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteTagIdMappingAction = (tenantId, authToken, userId, deleteId) => async dispatch => {
    let deleteTagMap = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteTagMap = await deleteTagMapApi({ tenantId, authToken, userId, deleteId });
        return deleteTagMap && deleteTagMap.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getTagldMappingDownloadCSVAction = (tagldMappingDownloadCSVObj) => async dispatch => {
    let tagldMappingDownloadCSV = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        tagldMappingDownloadCSV = await getTagldMappingDownloadCSVApi(tagldMappingDownloadCSVObj);
        return tagldMappingDownloadCSV;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getTagldMappingListadunitAllAction = (tagldMappingListadunitAllObj) => async dispatch => {
    let tagldMappingListadunitAll = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        tagldMappingListadunitAll = await getTagldMappingListadunitAllApi(tagldMappingListadunitAllObj);
        dispatch({
            type: GET_TAGLD_MAPPING_ADUNIT_ALL_LIST,
            tagldMappingListadunitAll,
        });
        return tagldMappingListadunitAll;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const tagldMappingtagldMappingAddAdunitmapAction = (tagldMappingAddAdunitmapObj) => async dispatch => {
    let tagldMappingAddAdunitmap = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        tagldMappingAddAdunitmap = await tagldMappingAddAdunitmapApi(tagldMappingAddAdunitmapObj);
        return tagldMappingAddAdunitmap;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// bulk upload
export const uploadSiteFileBulkCSVAction = (payload) => async dispatch => {
    let response = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        response = await uploadSiteFileBulkCSVApi(payload);
        return response;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const gettagldMappingBulkListAction = (tagldMappingBulkListObj) => async dispatch => {
    let tagldMappingBulkList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        tagldMappingBulkList = await gettagldMappingBulkListApi(tagldMappingBulkListObj);
        dispatch({
            type: GET_TAGLD_MAPPING_BULK_LIST,
            tagldMappingBulkList,
        });
        return tagldMappingBulkList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const tagldMappingBulkDownloadAction = (tagldMappingBulkDownloadObj) => async dispatch => {
    let tagldMappingBulkDownload = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        tagldMappingBulkDownload = await tagldMappingBulkDownloadApi(tagldMappingBulkDownloadObj);
        return tagldMappingBulkDownload;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getMediaReportAction = (tenantId, authToken, userId, mediaReportReqBody) => async dispatch => {
    let mediaReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        mediaReport = await getMediaReportApi({ tenantId, authToken, userId, mediaReportReqBody });
        dispatch({
            type: GET_MEDIA_REPORT,
            mediaReport,
        });
        return mediaReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// MediaReportAction

export const SaveMediaReportAction = (tenantId, authToken, userId, type) => async dispatch => {
    let saveMediaReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        saveMediaReport = await SaveMediaReportApi({ tenantId, authToken, userId, type });
        dispatch({
            type: GET_SAVE_REPORT,
            saveMediaReport,
        });
        return saveMediaReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getQPSReportAction = (tenantId, authToken, userId, qpsReportReqBody) => async dispatch => {
    let qpsReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        qpsReport = await getQpsReportApi({ tenantId, authToken, userId, qpsReportReqBody });
        dispatch({
            type: GET_QPS_REPORT,
            qpsReport,
        });
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getRevenueReportAction = (tenantId, authToken, userId, revenueReportReqBody) => async dispatch => {
    let revenueReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        revenueReport = await getRevenueReportApi({ tenantId, authToken, userId, revenueReportReqBody });
        dispatch({
            type: GET_REVENUE_REPORT,
            revenueReport,
        });
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getIonExchangeAction = (props) => async dispatch => {
    let ionExchange = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ionExchange = await getIonExchangeApi(props);
        dispatch({
            type: GET_ION_EXCHANGE_REPORT,
            ionExchange,
        });
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getIonExchangeDownloadReportAction = (props) => async dispatch => {
    let ionExchangeDownloadReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ionExchangeDownloadReport = await getIonExchangeDownloadApi(props);
        return (ionExchangeDownloadReport || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// admin api

export const getAdminSupplypartnerListAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let adminSupplypartnerData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminSupplypartnerData = await getAdminSupplypartnerListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_ADMIN_SUPPLY_PARTNER_LIST_ALL,
            adminSupplypartnerData,
        });
        return adminSupplypartnerData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getAdminTenantSupplypartnerListAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let adminTenantSupplypartnerData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminTenantSupplypartnerData = await getAdminTenantSupplypartnerListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_TENANT_SUPPLY_PARTNER_LIST_ALL,
            adminTenantSupplypartnerData,
        });
        return adminTenantSupplypartnerData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// admin Variable Api
export const getConfigurationDataAction = (objVariable) => async dispatch => {
    let configurationData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL_CONFIG,
            showSpinnerConfig: true,
        });
        // configurationData = await getConfigurationData(attributesObj);
        configurationData = await getAdminVariableListApi(objVariable);
        dispatch({
            type: GET_CONFIGURATION_DATA,
            configurationData,
        });
        return configurationData
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL_CONFIG,
            showSpinnerConfig: false,
        });
    }
};

export const getConfigurationTypeAction = (objVariable) => async dispatch => {
    let configurationData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        configurationData = await getAdminVariableListTypeApi(objVariable);
        return configurationData
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// export const addAdminAttrNameVariableAction = (objVariable) => async dispatch => {
//     let adminAttrNameVariableData = {};
//     try {
//         dispatch({
//             type: BEGIN_AJAX_CALL,
//             showSpinner: true,
//         });
//         adminAttrNameVariableData = await addAdminVariableApi(objVariable);
//         // dispatch({
//         //     type: GET_ADMIN_VARIABLE_LIST_ALL,
//         //     adminAttrNameVariableData,
//         // });
//         return (adminAttrNameVariableData || {});
//     } catch (err) {
//     } finally {
//         dispatch({
//             type: END_AJAX_CALL,
//             showSpinner: false,
//         });
//     }
// };

// export const addAdminAttributeValuesVariableAction = (objVariable) => async dispatch => {
//     let adminAttributeValuesVariableData = {};
//     try {
//         dispatch({
//             type: BEGIN_AJAX_CALL,
//             showSpinner: true,
//         });
//         adminAttributeValuesVariableData = await addAdminVariableApi(objVariable);
//         // dispatch({
//         //     type: GET_ADMIN_VARIABLE_LIST_ALL,
//         //     adminAttributeValuesVariableData,
//         // });
//         return adminAttributeValuesVariableData;
//     } catch (err) {
//     } finally {
//         dispatch({
//             type: END_AJAX_CALL,
//             showSpinner: false,
//         });
//     }
// };

export const addAdminVariableAction = (objVariable) => async dispatch => {
    let adminAttrNameVariableData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminAttrNameVariableData = await addAdminVariableApi(objVariable);
        // dispatch({
        //     type: GET_ADMIN_VARIABLE_LIST_ALL,
        //     adminAttrNameVariableData,
        // });
        return (adminAttrNameVariableData || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// User POST Action
export const getUsersListAction = (objVariable) => async dispatch => {
    let adminUserData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminUserData = await getUsersListApi(objVariable);
        dispatch({
            type: GET_ADMIN_USER_LIST_ALL,
            adminUserData,
        });
        return adminUserData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// admin User Api
export const getAdminUserListAction = (objVariable) => async dispatch => {
    let adminUserData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminUserData = await getAdminUserListApi(objVariable);
        dispatch({
            type: GET_ADMIN_USER_LIST_ALL,
            adminUserData,
        });
        return adminUserData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getAdminUserSingleListAction = (objVariable) => async dispatch => {
    let singleUserData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        singleUserData = await getAdminUserSingleListApi(objVariable);
        return singleUserData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addAdminUserAction = (objVariable) => async dispatch => {
    let adminUserData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminUserData = await addAdminUserApi(objVariable);
        return (adminUserData || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const userAdminStatusAction = (objVariable) => async dispatch => {
    let userStatusData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        userStatusData = await userAdminStatusApi(objVariable);
        return (userStatusData || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// admin Roles Api
export const getAdminRolesListAction = (objVariable) => async dispatch => {
    let adminRolesData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminRolesData = await getRolesListApi(objVariable);
        dispatch({
            type: GET_ADMIN_ROLES_LIST_ALL,
            adminRolesData,
        });
        return adminRolesData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postAdminFilterRolesAction = (objVariable) => async dispatch => {
    let rolesData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        rolesData = await postFilterRolesListApi(objVariable);
        return rolesData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getAdminMainRolesListAction = (objVariable) => async dispatch => {
    let adminMainRolesData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminMainRolesData = await getRolesListApi(objVariable);
        dispatch({
            type: GET_ADMIN_MAIN_ROLES_LIST_ALL,
            adminMainRolesData,
        });
        return adminMainRolesData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getAdminRoleSingleListAction = (objVariable) => async dispatch => {
    let singleRoleData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        singleRoleData = await getAdminRoleSingleListApi(objVariable);
        return singleRoleData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const addAdminRolesAction = (objVariable) => async dispatch => {
    let adminRolesrData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminRolesrData = await addAdminRolesApi(objVariable);
        return (adminRolesrData || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// admin Agreement Api
export const getAgreementListAction = (objVariable) => async dispatch => {
    let adminAgreementData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminAgreementData = await getAgreementListApi(objVariable);
        dispatch({
            type: GET_AGREEMENT_LIST_ALL,
            adminAgreementData,
        });
        return adminAgreementData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addAdminAgreementAction = (objVariable) => async dispatch => {
    let adminAgreementData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminAgreementData = await addAdminAgreementApi(objVariable);
        return (adminAgreementData || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getActiveSeatAction = (objVariable) => async dispatch => {
    let activeSeatListAll = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        activeSeatListAll = await getAgreementActiveSeatListApi(objVariable);
        dispatch({
            type: GET_AGREEMENT_ACTIVE_SEAT_LIST,
            activeSeatListAll,
        });
        return activeSeatListAll;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getParentTenantAction = (objVariable) => async dispatch => {
    let parentListAll = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        parentListAll = await getAgreementActiveSeatListApi(objVariable);
        dispatch({
            type: GET_AGREEMENT_PARENT_TENANT_LIST,
            parentListAll,
        });
        return parentListAll;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getPvtExchangeTenantListAction = (objVariable) => async dispatch => {
    let pvtExchangeTenantList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        pvtExchangeTenantList = await getPvtExchangeTenantListApi(objVariable);
        dispatch({
            type: GET_PVT_EXCHANGE_TENANT_LIST,
            pvtExchangeTenantList,
        });
        return pvtExchangeTenantList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getPvtDemandPartnerListAction = (objVariable) => async dispatch => {
    let pvtDemandPartnerList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        pvtDemandPartnerList = await getPvtDemandPartnerListApi(objVariable);
        dispatch({
            type: GET_PVT_DEMAND_PARTNER_LIST,
            pvtDemandPartnerList,
        });
        return pvtDemandPartnerList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
// admin Agreement Api
export const getAgreementConnectionListAction = (objVariable) => async dispatch => {
    let connectionAgreementData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        connectionAgreementData = await getAgreementConnectionListApi(objVariable);
        dispatch({
            type: GET_AGREEMENT_CONNECTION_LIST,
            connectionAgreementData,
        });
        return connectionAgreementData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// ---- * supplay path * ----
export const getIONSupplypathAction = (objVariable) => async dispatch => {
    let ionSupplypathData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ionSupplypathData = await getIONSupplypathListApi(objVariable);
        dispatch({
            type: GET_ADMIN_ION_SUPPLY_PARTNER_LIST_ALL,
            ionSupplypathData,
        });
        return (ionSupplypathData || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getPVTSupplypathAction = (objVariable) => async dispatch => {
    let pvtSupplypathData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        pvtSupplypathData = await getPVTSupplypathListApi(objVariable);
        dispatch({
            type: GET_ADMIN_PVT_SUPPLY_PARTNER_LIST_ALL,
            pvtSupplypathData,
        });
        return (pvtSupplypathData || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const updateIONSupplypathAction = (objVariable) => async dispatch => {
    let updateIONSupplypathData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        updateIONSupplypathData = await updateIONSupplypathApi(objVariable);
        return (updateIONSupplypathData || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const updatePvtSupplypathAction = (objVariable) => async dispatch => {
    let updatePvtSupplypathData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        updatePvtSupplypathData = await updatePvtSupplypathApi(objVariable);
        return (updatePvtSupplypathData || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postCuratedAction = ({ tenantId, authToken, userId, payload={}, params={} }) => async dispatch => {
    let postCuratedData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        postCuratedData = await postCuratedApi({ tenantId, authToken, userId, payload, params });
        return (postCuratedData || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getCuratedAction = ({ tenantId, authToken, userId, params={} }) => async dispatch => {
    let getCuratedData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getCuratedData = await getCuratedApi({ tenantId, authToken, userId, params });
        return (getCuratedData || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// ---- * Bidder Action * ----

export const getBidderReportAction = (tenantId, authToken, userId, bidderReportReqBody) => async dispatch => {
    let bidderReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        bidderReport = await getBidderReportApi({ tenantId, authToken, userId, bidderReportReqBody });
        dispatch({
            type: GET_BIDDER_REPORT,
            bidderReport,
        });
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getMediaPlanningReportAction = (tenantId, authToken, userId, mediaPlanningReportReqBody) => async dispatch => {
    let mediaPlanningReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        mediaPlanningReport = await getMediaPlanningReportApi({ tenantId, authToken, userId, mediaPlanningReportReqBody });
        dispatch({
            type: GET_MEDIA_PLANNING_REPORT,
            mediaPlanningReport,
        });
        return (mediaPlanningReport || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getMediaSaveReportAction = (tenantId, authToken, userId) => async dispatch => {
    let savedReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        savedReport = await getMediaSaveReportApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_MEDIA_SAVE_REPORT,
            savedReport,
        });
        return savedReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getGenerateReportAction = (tenantId, authToken, userId, mediaPlanningReportReqBody) => async dispatch => {
    let generateReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        generateReport = await getGenerateReportApi({ tenantId, authToken, userId, mediaPlanningReportReqBody });;
        dispatch({
            type: GET_GENERATE_REPORT,
            generateReport,
        });
        return generateReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getRefreshAction = (tenantId, authToken, userId, rowId, payload) => async dispatch => {
    let refreshReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        refreshReport = await getRefreshReportApi({ tenantId, authToken, userId, rowId, payload });;
        dispatch({
            type: GET_REFRESH_REPORT,
            refreshReport,
        });
        return refreshReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getMPRTrendAction = (tenantId, authToken, userId, mediaPlanningReportReqBody) => async dispatch => {
    let mediaPlanningReportTrend = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        mediaPlanningReportTrend = await getMediaPlanningReportApi({ tenantId, authToken, userId, mediaPlanningReportReqBody });
        dispatch({
            type: GET_MEDIA_PLANNING_REPORT_TREND,
            mediaPlanningReportTrend,
        });
        return (mediaPlanningReportTrend || {})
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getCampaignPerformanceReportAction = (tenantId, authToken, userId, campaignPerformanceReportReqBody) => async dispatch => {
    let campaignPerformanceReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignPerformanceReport = await getCampaignPerformanceReportApi({ tenantId, authToken, userId, campaignPerformanceReportReqBody });
        dispatch({
            type: GET_CAMPAIGN_PERFORMANCE_REPORT,
            campaignPerformanceReport,
        });
        return campaignPerformanceReport
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getAdminReportDataAction = (tenantId, authToken, userId, x, action) => async dispatch => {
    let adminReportData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        adminReportData = await getAdminReportDataApi({ tenantId, authToken, userId, x, action });
        // dispatch({
        //     // type: GET_CAMPAIGN_PERFORMANCE_REPORT_FILTER_LIST,
        //     adminReportData,
        // });
        return adminReportData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getCampaignReportFliterListAction = (tenantId, authToken, userId) => async dispatch => {
    let campaignPerformanceReportFilterList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignPerformanceReportFilterList = await getCampaignPerformanceReportFilterListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_CAMPAIGN_PERFORMANCE_REPORT_FILTER_LIST,
            campaignPerformanceReportFilterList,
        });
        return campaignPerformanceReportFilterList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getDataPartnerCustomFliterAction = (tenantId, authToken, userId) => async dispatch => {
    let dataPartnerCustomFliterList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        dataPartnerCustomFliterList = await getDataPartnerCustomFilterApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_DATA_PARTNER_CUSTOM_FILTER_LIST,
            dataPartnerCustomFliterList,
        });
        return dataPartnerCustomFliterList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getSaveReportAction = (tenantId, authToken, userId, type) => async dispatch => {
    let saveReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        saveReport = await getSaveReportApi({ tenantId, authToken, userId, type });
        dispatch({
            type: GET_SAVE_REPORT,
            saveReport,
        });
        return saveReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// vastValidatorApi
export const vastValidatorAction = (tenantId, authToken, userId, url) => async dispatch => {
    console.log("API CALLED", url);
    let validationReponse = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        validationReponse = await vastValidatorApi({ tenantId, authToken, userId, url });
        // dispatch({
        //     type: GET_USER_REACH_REPORT,
        //     validationReponse,
        // });
        return validationReponse
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
}

export const getSaveReportDownloadAction = (tenantId, authToken, userId, param) => async dispatch => {
    let saveReportData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        saveReportData = await getSaveReportDownloadApi({ tenantId, authToken, userId, param });
        dispatch({
            type: GET_SAVE_REPORT_DOWNLOAD,
            saveReportData,
        });
        return saveReportData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getUserReachReportAction = (tenantId, authToken, userId, userReachReportReqBody) => async dispatch => {
    let userReachReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        userReachReport = await getUserReachReportApi(tenantId, authToken, userId, userReachReportReqBody);
        dispatch({
            type: GET_USER_REACH_REPORT,
            userReachReport,
        });
        return userReachReport
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getSaveReportAddAction = (tenantId, authToken, userId, payload) => async dispatch => {
    let saveReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        saveReport = await addSaveReportApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: GET_SAVE_REPORT,
            saveReport,
        });
        return saveReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getSaveReportRefreshAction = (tenantId, authToken, userId, payload, id) => async dispatch => {
    let saveReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        saveReport = await refreshSaveReportApi({ tenantId, authToken, userId, payload, id });
        dispatch({
            type: GET_SAVE_REPORT,
            saveReport,
        });
        return saveReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getDataPartnerSegementReportAction = (tenantId, authToken, userId, segmentReportReqBody, action) => async dispatch => {
    let segmentReportTrend = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        segmentReportTrend = await getDataPartnerSegementReportApi({ tenantId, authToken, userId, segmentReportReqBody, action });
        dispatch({
            type: GET_CAMPAIGN_SEGMENT_REPORT_TREND,
            segmentReportTrend,
        });
        return segmentReportTrend
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getDataPartnerDailyAction = (tenantId, authToken, userId, campaignPerformanceReportReqBody) => async dispatch => {
    let campaignPerformanceReportTrend = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignPerformanceReportTrend = await getDataPartnerDailyReportApi({ tenantId, authToken, userId, campaignPerformanceReportReqBody });
        dispatch({
            type: GET_CAMPAIGN_PERFORMANCE_REPORT_TREND,
            campaignPerformanceReportTrend,
        });
        return campaignPerformanceReportTrend
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getCPRTrendAction = (tenantId, authToken, userId, campaignPerformanceReportReqBody) => async dispatch => {
    let campaignPerformanceReportTrend = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignPerformanceReportTrend = await getCampaignPerformanceReportApi({ tenantId, authToken, userId, campaignPerformanceReportReqBody });
        dispatch({
            type: GET_CAMPAIGN_PERFORMANCE_REPORT_TREND,
            campaignPerformanceReportTrend,
        });
        return campaignPerformanceReportTrend
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

//************** DATA PARTNER CAMPAING PERFORMANCE REPORT */

export const getDataPartnerCampaignReportAction = (tenantId, authToken, userId, campaignPerformanceReportReqBody, action) => async dispatch => {
    let campaignPerformanceReportTrend = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignPerformanceReportTrend = await getDataPartnerCampaignReportApi({ tenantId, authToken, userId, campaignPerformanceReportReqBody, action });
        dispatch({
            type: GET_CAMPAIGN_PERFORMANCE_REPORT_TREND,
            campaignPerformanceReportTrend,
        });
        return campaignPerformanceReportTrend
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getCampaignListAllReportAction = (campaignObj) => async dispatch => {
    let campaignListAllReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignListAllReport = await getCampaignListAllReportApi(campaignObj);
        dispatch({
            type: GET_CAMPAIGN_LIST_ALL_REPORT,
            campaignListAllReport,
        });
        return campaignListAllReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getCampaignEditIdReportAction = (tenantId, authToken, userId, editId) => async dispatch => {
    let response = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        response = await getCampaignEditIdReportApi({ tenantId, authToken, userId, editId });
        return response;
        // dispatch({
        //     type: GET_CAMPAIGN_LIST_ALL_REPORT,
        //     campaignListAllReport,
        // });
        // return campaignListAllReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// campaign  Private Market Place
export const getPrivateMarketPlaceAction = (PMPObj) => async dispatch => {
    let privateMarketPlaceList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        privateMarketPlaceList = await getPrivateMarketPlaceApi(PMPObj);
        dispatch({
            type: GET_PRIVATE_MARKET_PLACE_LIST,
            privateMarketPlaceList,
        });
        return privateMarketPlaceList;
        // return campaignListAllReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addPrivateMarketPlaceAction = (PMPObj) => async dispatch => {
    let addPrivateMarketPlace = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addPrivateMarketPlace = await addPrivateMarketPlaceApi(PMPObj);
        return addPrivateMarketPlace;
        // dispatch({
        //     type: GET_CAMPAIGN_LIST_ALL_REPORT,
        //     campaignListAllReport,
        // });
        // return campaignListAllReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deletePrivateMarketPlaceAction = (PMPObj) => async dispatch => {
    let deletePrivateMarketPlace = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deletePrivateMarketPlace = await deletePrivateMarketPlaceApi(PMPObj);
        return deletePrivateMarketPlace;
        // dispatch({
        //     type: GET_CAMPAIGN_LIST_ALL_REPORT,
        //     campaignListAllReport,
        // });
        // return campaignListAllReport;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



// --------* io list * ------
export const getIoListAllReportAction = (tenantId, authToken, userId, params, payload) => async dispatch => {
    let ioListAllReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ioListAllReport = await getIoListAllReportApi({ tenantId, authToken, userId, params, payload });
        dispatch({
            type: GET_IO_LIST_ALL_REPORT,
            ioListAllReport,
        });
        return ioListAllReport
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addIoReportAction = (tenantId, authToken, userId, addIoReqBody, addParams) => async dispatch => {
    let ioAddReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ioAddReport = await addIoReportApi({ tenantId, authToken, userId, addIoReqBody, addParams });
        return ioAddReport && ioAddReport.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getIoAdvertisersReportAction = (tenantId, authToken, userId) => async dispatch => {
    let ioAdvertisersReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ioAdvertisersReport = await getIoAdvertisersReportApi({ tenantId, authToken, userId, tenantType: { tenantType: 'advertiser' } });
        dispatch({
            type: GET_IO_ADVERTISER_LIST_REPORT,
            ioAdvertisersReport,
        });
        return ioAdvertisersReport
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getExchangeReportAction = (tenantId, authToken, userId) => async dispatch => {
    let exchangeReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        exchangeReport = await getIoAdvertisersReportApi({ tenantId, authToken, userId, tenantType: { tenantType: 'exchange' } });
        dispatch({
            type: GET_EXCHANGE_LIST_REPORT,
            exchangeReport,
        });
        return (exchangeReport || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteIoAction = (tenantId, authToken, userId, deleteId) => async dispatch => {
    let deleteIo = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteIo = await deleteIoApi({ tenantId, authToken, userId, deleteId });
        return deleteIo && deleteIo.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getIoEditIdReportAction = (tenantId, authToken, userId, editId, ioTenantId) => async dispatch => {
    let ioEditIdReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        ioEditIdReport = await getIoEditIdReportApi({ tenantId, authToken, userId, editId, ioTenantId });
        return ioEditIdReport
        // dispatch({
        //     type: GET_IO_LIST_ALL_REPORT,
        //     ioListAllReport,
        // });
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// ---- * Country/state/city * ----

export const getCountryAction = (countryObj) => async dispatch => {
    let getCountryList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getCountryList = await getCountryListApi(countryObj);
        dispatch({
            type: GET_COUNTRY_LIST,
            getCountryList,
        });
        return getCountryList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// export const getStateAction = (countryCode) => async dispatch => {
//     let getStateList = {};
//     try {
//         dispatch({
//             type: BEGIN_AJAX_CALL,
//             showSpinner: true,
//         });
//         getStateList = await getStateListApi({ countryCode });
//         dispatch({
//             type: GET_STATE_LIST,
//             getStateList,
//         });
//         return getStateList
//     } catch (err) {
//     } finally {
//         dispatch({
//             type: END_AJAX_CALL,
//             showSpinner: false,
//         });
//     }
// };

export const getStateAction = (stateObj) => async dispatch => {
    let getStateList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getStateList = await getStateListApi(stateObj);
        dispatch({
            type: GET_STATE_LIST,
            getStateList,
        });
        return getStateList
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// export const getCityAction = (cnscCode) => async dispatch => {
//     let getCityList = {};
//     try {
//         dispatch({
//             type: BEGIN_AJAX_CALL,
//             showSpinner: true,
//         });
//         getCityList = await getCityListApi({ cnscCode });
//         dispatch({
//             type: GET_CITY_LIST,
//             getCityList,
//         });
//         return getCityList;
//     } catch (err) {
//     } finally {
//         dispatch({
//             type: END_AJAX_CALL,
//             showSpinner: false,
//         });
//     }
// };
export const getCityAction = (cityObj) => async dispatch => {
    let getCityList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getCityList = await getCityListApi(cityObj);
        dispatch({
            type: GET_CITY_LIST,
            getCityList,
        });
        return getCityList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// state city ( without countryCode/ cnscCode)
export const getStateAllAction = (stateObj) => async dispatch => {
    let getStateAllList = {};
    let getStateAllFilterList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getStateAllList = await getStateListApi(stateObj);
        getStateAllFilterList = groupBy(getStateAllList.responseList, (item) => item.stateName.charAt(0).toLowerCase());
        dispatch({
            type: GET_STATE_LIST_ALL,
            getStateAllList,
            getStateAllFilterList
        });
        return getStateAllList
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getCityAllAction = (cityObj) => async dispatch => {
    let getCityAllList = {};
    let getCityAllFilterList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getCityAllList = await getCityListApi(cityObj);
        getCityAllFilterList = groupBy(getCityAllList.responseList, (item) => item.cityName.charAt(0).toLowerCase());
        dispatch({
            type: GET_CITY_LIST_ALL,
            getCityAllList,
            getCityAllFilterList
        });
        return getCityAllList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getPincodeAllAction = (pincodeObj) => async dispatch => {
    let getPincodeAllList = {};
    let getPincodeAllFilterList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getPincodeAllList = await getPinCodeListApi(pincodeObj);
        getPincodeAllFilterList = groupBy(getPincodeAllFilterList.responseList, (item) => item.pincode);
        dispatch({
            type: GET_PINCODE_LIST_ALL,
            getPincodeAllList,
            getPincodeAllFilterList
        });
        return getPincodeAllList
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



// ----- * Campaign * -----

// export const getCampaignSubCategoryReportAction = (valueId, token) => async dispatch => {
//     let getCampaignSubCategoryReport = {};
//     try {
//         dispatch({
//             type: BEGIN_AJAX_CALL,
//             showSpinner: true,
//         });
//         getCampaignSubCategoryReport = await getCampaignSubCategoryReportApi({ valueId }, token);
//         dispatch({
//             type: GET_CAMPAIGN_SUB_CATEGORY,
//             getCampaignSubCategoryReport,
//         });
//         return getCampaignSubCategoryReport
//     } catch (err) {
//     } finally {
//         dispatch({
//             type: END_AJAX_CALL,
//             showSpinner: false,
//         });
//     }
// };

export const getCampaignSubCategoryReportAction = (tenantId, authToken, userId, payload, addParams) => async dispatch => {
    let getCampaignSubCategoryReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getCampaignSubCategoryReport = await getCampaignSubCategoryReportApi({ tenantId, authToken, userId, payload, addParams });
        dispatch({
            type: GET_CAMPAIGN_SUB_CATEGORY,
            getCampaignSubCategoryReport,
        });
        return getCampaignSubCategoryReport
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addCampaignAction = (tenantId, authToken, userId, addCampaignBody, editId) => async dispatch => {
    let campaign = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaign = await addCampaignApi({ tenantId, authToken, userId, addCampaignBody, editId });
        return campaign && campaign.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteCampaignAction = (tenantId, authToken, userId, deleteId) => async dispatch => {
    let deleteCampaign = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteCampaign = await deleteCampaignApi({ tenantId, authToken, userId, deleteId });
        return deleteCampaign && deleteCampaign.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getCampaignCategoryTypeAction = (campaignCategoryObj) => async dispatch => {
    let campaignCategoryTypeList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignCategoryTypeList = await getCampaignCategoryTypeApi(campaignCategoryObj);
        dispatch({
            type: GET_CAMPAIGN_CATEGORY_TYPE_LIST,
            campaignCategoryTypeList,
        });
        return campaignCategoryTypeList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

//-----* trouble shooter *-----

export const getStrategyBudgetDataAction = (parameters) => async dispatch => {
    let strategyBudgetData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        strategyBudgetData = await getStrategyBudgetDataApi(parameters);
        return strategyBudgetData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getStrategyBudgetSpendDataAction = (parameters) => async dispatch => {
    let strategyBudgetSpendData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        strategyBudgetSpendData = await getStrategyBudgetSpendDataApi(parameters);
        return strategyBudgetSpendData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getDayPartDataAction = (parameters) => async dispatch => {
    let dayPartData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        dayPartData = await getDayPartDataForTrobleShooter(parameters);
        return dayPartData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// ---* creative *---

export const getAdvertiserCreativeGroupAction = (tenantId, authToken, userId, advertiserId) => async dispatch => {
    let getAdvertiserCreativeGroup = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getAdvertiserCreativeGroup = await getAdvertiserCreativeGroupApi(tenantId, authToken, userId, advertiserId);
        dispatch({
            type: GET_ADVERTISER_CREATIVE_GROUP,
            getAdvertiserCreativeGroup,
        });
        return getAdvertiserCreativeGroup
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addCreativeAction = (tenantId, authToken, userId, payload, addParams) => async dispatch => {
    let creative = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        creative = await addCreativeApi({ tenantId, authToken, userId, payload, addParams });
        return creative && creative.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const deleteCreativeAction = (creativeObj) => async dispatch => {
    let deleteCreative = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteCreative = await deleteCreativeApi(creativeObj);
        return deleteCreative && deleteCreative.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const editCreativeAction = (tenantId, authToken, userId, advertiserId, editId) => async dispatch => {
    let editCreative = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        editCreative = await editCreativeApi({ tenantId, authToken, userId, advertiserId, editId });
        return editCreative;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const uploadFileAction = (payload, advertiserId, accept, size = '') => async dispatch => {
    let response = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        response = await uploadFileActionApi({ payload, advertiserId, accept, size });
        return response;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const uploadPlatformFileAction = (payload, advertiserId, accept) => async dispatch => {
    let response = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        response = await uploadPlatformFileActionApi({ payload, advertiserId, accept });
        return response;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const uploadPlatformConfigurationFileAction = (payload) => async dispatch => {
    let response = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        response = await uploadPlatformConfigurationFileActionApi({ payload });
        return response;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const uploadSupplyPartnerFileAction = (payload, advertiserId, accept) => async dispatch => {
    let response = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        response = await uploadSupplyPartnerFileActionApi({ payload, advertiserId, accept });
        return response;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const downloadDomainListAction = (tenantId, authToken, userId, spId) => async dispatch => {
    let domainData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        domainData = await getDownloadDomainListApi({ tenantId, authToken, userId, spId });
        return domainData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
// ---- * Admin * ----

// * Tenant * 
export const getTenantAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let tenantListAll = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        tenantListAll = await getTenantApi({ tenantId, authToken, userId });
        // return tenantListAll
        dispatch({
            type: GET_TENANT_LIST_ALL,
            tenantListAll,
        });
        return tenantListAll
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addTenantAction = (tenantId, authToken, userId, payload, addParams) => async dispatch => {
    let addTenant = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addTenant = await addTenantApi({ tenantId, authToken, userId, payload, addParams });
        return addTenant && addTenant.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteTenantAction = (tenantId, authToken, userId, deleteId) => async dispatch => {
    let deleteTenant = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteTenant = await deleteTenantApi({ tenantId, authToken, userId, deleteId });
        return deleteTenant && deleteTenant;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const editTenantAction = (tenantId, authToken, userId, editId, mgrData) => async dispatch => {
    let editTenant = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        editTenant = await editTenantApi({ tenantId, authToken, userId, editId, mgrData });
        return editTenant && editTenant;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



//---- * Supply Partner* --

export const getSupplyPartnerAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let supplyPartnerListAll = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        supplyPartnerListAll = await getSupplyPartnerApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_SUPPLY_PARTNER_LIST_ALL,
            supplyPartnerListAll,
        });
        return supplyPartnerListAll;


    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addSupplyPartnerAction = (tenantId, authToken, userId, payload, addParams) => async dispatch => {
    let addSupplyPartner = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addSupplyPartner = await addSupplyPartnerApi({ tenantId, authToken, userId, payload, addParams });
        return addSupplyPartner && addSupplyPartner.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteSupplyPartnerAction = (tenantId, authToken, userId, deleteId) => async dispatch => {
    let deleteSupplyPartner = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteSupplyPartner = await deleteSupplyPartnerApi({ tenantId, authToken, userId, deleteId });
        return deleteSupplyPartner && deleteSupplyPartner.responeCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const editSupplyPartnerAction = (tenantId, authToken, userId, editId) => async dispatch => {
    let editSupplyPartner = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        editSupplyPartner = await editSupplyPartnerApi({ tenantId, authToken, userId, editId });
        return editSupplyPartner && editSupplyPartner;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

//---- * Feature * --
export const getListFeatureAction = (featureObj, type) => async dispatch => {
    let listFeatureAll = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        listFeatureAll = await getListFeatureApi(featureObj);

        dispatch({
            type: GET_LIST_FEATURE_ALL,
            listFeatureAll,
            responseType: type
        });
        return (listFeatureAll || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getFeatureListAction = (featureObj) => async dispatch => {
    let featurelist = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        featurelist = await getFeatureListApi(featureObj);

        dispatch({
            type: GET_FEATURE_LIST_ALL,
            featurelist,
        });
        return (featurelist || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addFeatureAction = (featureObj) => async dispatch => {
    let addfeature = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addfeature = await addFeatureApi(featureObj);
        return (addfeature || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addFeatureAttributeAction = (featureObj) => async dispatch => {
    let addFeatureAttribute = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addFeatureAttribute = await addFeatureAttributeApi(featureObj);
        return (addFeatureAttribute || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};



// --- * Demand Partner Seat * --
export const getDemandPartnerSeatListAction = (demandObj) => async dispatch => {
    let demandpartnerSeatList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        demandpartnerSeatList = await getDemandpartnerSeatListApi(demandObj);
        dispatch({
            type: GET_DEMAND_PARTNER_SEAT_LIST_ALL,
            demandpartnerSeatList,
        });
        return demandpartnerSeatList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// ---- Mediation demand Seat list ---------------
export const getMediationDemandPartnerSeatListAction = (demandObj) => async dispatch => {
    let mediationDemandPartnerNameList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        mediationDemandPartnerNameList = await getMediationDemandpartnerSeatListApi(demandObj);
        dispatch({
            type: GET_MEDIATION_DEMAND_PARTNER_SEAT_LIST_ALL,
            mediationDemandPartnerNameList,
        });
        return mediationDemandPartnerNameList;

    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

//------* Currency Rate *------------
export const getCurrencyRateListAction = (demandObj) => async dispatch => {
    let currencyRateList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        currencyRateList = await getCurrencyRateListApi(demandObj);
        dispatch({
            type: GET_CURRENCY_RATE_LIST,
            currencyRateList,
        });
        return currencyRateList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const addCurrencyRateAction = (tenantId, authToken, userId, form) => async dispatch => {
    let addRate = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addRate = await addCurrencyRateApi(tenantId, authToken, userId, form);
        dispatch({
            type: ADD_CURRENCY_RATE,
            addRate,
        });
        return addRate;

    } catch (err) {
    } finally {

        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

//---- * Demand Partner * --

export const getDemandPartnerListAction = (demandObj) => async dispatch => {
    let demandpartnerList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        demandpartnerList = await getDemandpartnerListApi(demandObj);
        dispatch({
            type: GET_DEMAND_PARTNER_LIST_ALL,
            demandpartnerList,
        });
        return demandpartnerList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addDemandPartnerAction = (demandPartnerObj) => async dispatch => {
    let addDemandPartner = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addDemandPartner = await addAdminDemandPartnerApi(demandPartnerObj);
        return addDemandPartner;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getDemandPartnerNameFilterAction = (demandObj) => async dispatch => {
    let demandPartnerNameList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        demandPartnerNameList = await getDemandPartnerNamesFilterApi(demandObj);

        dispatch({
            type: GET_DEMAND_PARTNER_NAME_LIST_ALL,
            demandPartnerNameList,
        });
        return demandPartnerNameList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getTenantDemandPartnerListAction = (demandObj) => async dispatch => {
    let tenantDemandpartnerList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        tenantDemandpartnerList = await getTenantDemandpartnerListApi(demandObj);
        dispatch({
            type: GET_TENANT_DEMAND_PARTNER_LIST,
            tenantDemandpartnerList,
        });
        return tenantDemandpartnerList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getRoleAccessPermissions = (permissionObj) => async dispatch => {
    let getUserPermissionList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        let data = await roleAccessPermissions(permissionObj);
        let permissionsList = [];
        data.responseList.forEach((a) => {
            if (a.roleDetails && a.roleDetails.length) {
                a.roleDetails.forEach((b) => {
                    permissionsList.push((`${b.moduleName}-${b.featureName}-${b.useCaseName}-${b.bcrumName}`.toUpperCase()).trim());
                });
            }
        });
        getUserPermissionList = uniq(permissionsList);

        let defaultMainRoute = ''
        // default route dashboard
        let defaultRoute = {};
        let dashboard = MODULE_PERMISSIONS.DASHBOARD.find(a => getUserPermissionList.indexOf(a) > -1);
        if (dashboard && ROUTE[dashboard]) {
            defaultRoute.dashboard = ROUTE[dashboard];
            defaultMainRoute = ROUTE[dashboard];
        }

        // default route io
        let io = MODULE_PERMISSIONS.IO.find(a => getUserPermissionList.indexOf(a) > -1);
        if (io && ROUTE[io]) {
            defaultRoute.io = ROUTE[io];
            if (!defaultMainRoute) {
                defaultMainRoute = ROUTE[io];
            }
        }

        // default route campaign
        let campaign = MODULE_PERMISSIONS.CAMPAIGN.find(a => getUserPermissionList.indexOf(a) > -1);
        if (campaign && ROUTE[campaign]) {
            defaultRoute.campaign = ROUTE[campaign];
            if (!defaultMainRoute) {
                defaultMainRoute = ROUTE[campaign];
            }
        }

        // default route creative
        let creative = MODULE_PERMISSIONS.CREATIVE.find(a => getUserPermissionList.indexOf(a) > -1);
        if (creative && ROUTE[creative]) {
            defaultRoute.creative = ROUTE[creative];
            if (!defaultMainRoute) {
                defaultMainRoute = ROUTE[creative];
            }
        }

        // default route admin
        let admin = MODULE_PERMISSIONS.ADMIN.find(a => getUserPermissionList.indexOf(a) > -1);
        if (admin && ROUTE[admin]) {
            defaultRoute.admin = ROUTE[admin];
            if (!defaultMainRoute) {
                defaultMainRoute = ROUTE[admin];
            }
        }

        // default route audience
        let audience = MODULE_PERMISSIONS.AUDIENCE.find(a => getUserPermissionList.indexOf(a) > -1);
        if (audience && ROUTE[audience]) {
            defaultRoute.audience = ROUTE[audience];
            if (!defaultMainRoute) {
                defaultMainRoute = ROUTE[audience];
            }
        }

        // default route exchange
        let exchange = MODULE_PERMISSIONS.EXCHANGE.find(a => getUserPermissionList.indexOf(a) > -1);
        if (exchange && ROUTE[exchange]) {
            defaultRoute.exchange = ROUTE[exchange];
            if (!defaultMainRoute) {
                defaultMainRoute = ROUTE[exchange];
            }
        }

        localStorage.setItem('getUserPermissionList', JSON.stringify(getUserPermissionList));
        localStorage.setItem('defaultRoute', JSON.stringify(defaultRoute));
        localStorage.setItem('defaultMainRoute', JSON.stringify(defaultMainRoute));

        dispatch({
            type: GET_USER_PERMISSION,
            getUserPermissionList,
            defaultRoute,
            defaultMainRoute
        });
        return { getUserPermissionList, data, defaultMainRoute };
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// ---- * Audience * ----

// * Apr * 
export const getAprAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let aprListAll = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        aprListAll = await getAprApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_APR_LIST_ALL,
            aprListAll,
        });
        return aprListAll
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getSegmentListAction = ({ tenantId, authToken, userId, params, Addparams }) => async dispatch => {
    let getSegmentList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getSegmentList = await getSegmentListApi({ tenantId, authToken, userId, params, Addparams });
        dispatch({
            type: GET_SEGMENT_LIST_ALL,
            getSegmentList,
        });
        return getSegmentList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getHierarchyDisplayListAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let getHierarchyList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getHierarchyList = await getHierarchyDisplayListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_HIERARCHY_LIST_ALL,
            getHierarchyList,
        });
        return getHierarchyList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getHierarchyListAction = ({ tenantId, authToken, userId, key, country, id }) => async dispatch => {
    let getHierarchyList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getHierarchyList = await getHierarchyListApi({ tenantId, authToken, userId, key, country, id });
        dispatch({
            type: GET_HIERARCHY_LIST_ALL,
            getHierarchyList,
        });
        return getHierarchyList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const postCampaignHierarchyListAction = ({ tenantId, authToken, userId, key, payload, country, id }) => async dispatch => {
    let campaignHierarchyList = {};
    try {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: true,
        });
        campaignHierarchyList = await postCampaignHierarchyListApi({ tenantId, authToken, userId, key, payload, country, id });
        return campaignHierarchyList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getBrandedHierarchyListAction = (tenantId, authToken, userId, params = {}) => async dispatch => {
    let brandedHierarchyList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        brandedHierarchyList = await getBrandedHierarchyListApi({ tenantId, authToken, userId, params });
        dispatch({
            type: GET_BRANDED_HIERARCHY_LIST,
            brandedHierarchyList,
        });
        return brandedHierarchyList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addAprAction = (tenantId, authToken, userId, payload, addParams) => async dispatch => {
    let addApr = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addApr = await addAprApi({ tenantId, authToken, userId, payload, addParams });
        return addApr && addApr.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getAprEditAction = (tenantId, authToken, userId, editId) => async dispatch => {
    let aprEditReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        aprEditReport = await getAprEditApi({ tenantId, authToken, userId, editId });
        return aprEditReport
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const aprDownloadAction = (tenantId, authToken, userId, aprId) => async dispatch => {
    let aprDownload = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        aprDownload = await aprDownloadApi({ tenantId, authToken, userId, aprId });
        return aprDownload
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
}

export const addHierarchyAction = (tenantId, authToken, userId, payload, addParams) => async dispatch => {
    let addHierarchy = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addHierarchy = await addHierarchyApi({ tenantId, authToken, userId, payload, addParams });
        return addHierarchy;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
}

export const deleteHierarchyAction = (tenantId, authToken, userId, deleteId) => async dispatch => {
    let deleteHierarchyData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteHierarchyData = await deleteHierarchyApi({ tenantId, authToken, userId, deleteId });
        return deleteHierarchyData
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
}

export const addSegmentAction = (tenantId, authToken, userId, payload, addParams) => async dispatch => {
    let addSegment = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addSegment = await addSegmentApi({ tenantId, authToken, userId, payload, addParams });
        return addSegment && addSegment.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getDataPixelEditAction = (tenantId, authToken, userId, editId) => async dispatch => {
    let dataPixelEditReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        dataPixelEditReport = await getDataPixelEditApi({ tenantId, authToken, userId, editId });
        return dataPixelEditReport
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// Data Capture


export const addDataCaptureAction = (dataCaptureObj) => async dispatch => {
    let addDataCapture = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addDataCapture = await addDataCaptureApi(dataCaptureObj);
        return (addDataCapture || {})
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getDataCaptureAction = (dataCaptureObj) => async dispatch => {
    let getDataCapture = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getDataCapture = await getDataCaptureApi(dataCaptureObj);
        return (getDataCapture || {})
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const getDataCaptureListAction = (dataCaptureObj) => async dispatch => {
    let getDataCaptureList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getDataCaptureList = await getDataCaptureListApi(dataCaptureObj);
        dispatch({ type: GET_DATA_CAPTURE_LIST_ALL, getDataCaptureList });
        return (getDataCaptureList || {})
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const deleteDataCaptureAction = (dataCaptureObj) => async dispatch => {
    let deleteDataCapture = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteDataCapture = await deleteDataCaptureApi(dataCaptureObj);
        return (deleteDataCapture || {})
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getSegmentEditAction = (tenantId, authToken, userId, editId) => async dispatch => {
    let segmentEditReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        segmentEditReport = await getsegmentEditApi({ tenantId, authToken, userId, editId });
        return segmentEditReport
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// audiance Hierarchy
export const audienceHierarchylistAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let audienceHierarchyList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        audienceHierarchyList = await getAudhierarchyListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_AUDIENCE_HIERARCHY_LIST_ALL,
            audienceHierarchyList,
        });
        return audienceHierarchyList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// audiance Hierarchy
export const downloadingConversionTagAction = (dataConversionTagObj) => async dispatch => {
    let downloadingConversionTag = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        downloadingConversionTag = await downloadingConversionTagApi(dataConversionTagObj);
        return (downloadingConversionTag || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// audience hierarchy search
export const searchAudienceSegmentAction = (tenantId, authToken, userId, params = {}) => async dispatch => {
    let segmentList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        segmentList = await searchAudienceSegmentApi({ tenantId, authToken, userId, params });
        return segmentList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


//Hierarchy Manager Add Custom Search Action
export const getSegmentSearchAction = ({ tenantId, authToken, userId, params = '', id = '', searchParam }) => async dispatch => {
    let getHierarchyManagerCustomSearchList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getHierarchyManagerCustomSearchList = await getSegmentSearchApi({ tenantId, authToken, userId, params, id, searchParam });
        return (getHierarchyManagerCustomSearchList || {})
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
}

//Hierarchy Manager Custom List
export const getHierarchyManagerCustomListAction = (tenantId, authToken, userId, params = {}) => async dispatch => {
    let getHierarchyManagerCustomList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        console.log();
        getHierarchyManagerCustomList = await getHierarchyManagerCustomListApi({ tenantId, authToken, userId, params });
        return (getHierarchyManagerCustomList || {})
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

//Hierarchy Manager Custom Clone By Id
export const getHierarchyListCloneByIdAction = ({ tenantId, authToken, userId, key, type }) => async dispatch => {
    let getHierarchyListCloneByIdCustomList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getHierarchyListCloneByIdCustomList = await getHierarchyListCloneByIdApi({ tenantId, authToken, userId, key, type });
        return (getHierarchyListCloneByIdCustomList || {})
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
}

export const postHierarchySegmentDeleteAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let hierarchySegmentDeletedData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        hierarchySegmentDeletedData = await postHierarchySegmentDeleteApi({ tenantId, authToken, userId, payload });
        return (hierarchySegmentDeletedData || {})
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
}

export const postHierarchySegmentRecallAction = ({ tenantId, authToken, userId, params, payload }) => async dispatch => {
    let hierarchySegmentRecallData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        hierarchySegmentRecallData = await postHierarchySegmentRecallApi({ tenantId, authToken, userId, params, payload });
        return (hierarchySegmentRecallData || {})
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
}

export const postHierarchySegmentUpdateAction = ({ tenantId, authToken, userId, params, payload }) => async dispatch => {
    let hierarchySegmentCostData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        hierarchySegmentCostData = await postHierarchySegmentUpdateApi({ tenantId, authToken, userId, params, payload });
        return (hierarchySegmentCostData || {})
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
}

// get audiance pixel micros
export const getAudiancePixelMicrosAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let getAudiancePixelMicros = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getAudiancePixelMicros = await getAudiancePixelMicrosApi({ tenantId, authToken, userId });
        return (getAudiancePixelMicros || {})
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// check unique tag and postback name

export const checkTagAndPostbackuniqueAction = ({ tenantId, authToken, userId, checkParam }) => async dispatch => {

    let checkTagPostbackName = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        checkTagPostbackName = await checkTagAndPostbackuniqueApi({ tenantId, authToken, userId, checkParam });
        return (checkTagPostbackName || {})
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
}


// upload jstag script action
export const uploadScriptAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let response = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        response = await uploadScriptApi({ tenantId, authToken, userId, payload });
        return response;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// get add tenant pin code action
export const getPinCodeAction = (pincodeObj) => async dispatch => {

    let getPincodeList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getPincodeList = await getPinCodeListApi(pincodeObj);
        dispatch({
            type: GET_PINCODE_LIST,
            getPincodeList,
        });
        return getPincodeList;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// forgot Password 

export const forgotPasswordAction = (authUser) => async dispatch => {
    let forgotPassword = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        forgotPassword = await forgotPasswordApi(authUser);
        return (forgotPassword || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const validateResetPassword = ({ rp }) => async dispatch => {
    let validateResetPassword = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        validateResetPassword = await validateResetPasswordApi({ rp });
        return (validateResetPassword || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const changePasswordAction = ({ password, rp }) => async dispatch => {
    let changePassword = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        changePassword = await changePasswordApi({ password, rp });
        return (changePassword || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// campaign screen site list
// * Apr *
export const getCampaignSiteAction = ({ tenantId, authToken, userId, payload }) => async dispatch => {
    let getCampaignSiteAll = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        getCampaignSiteAll = await getCampaignSiteApi({ tenantId, authToken, userId, payload });
        dispatch({
            type: GET_CAMPAIGN_SITE_LIST_ALL,
            getCampaignSiteAll,
        });
        return getCampaignSiteAll;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const uploadSiteFileAction = (payload) => async dispatch => {
    let response = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        response = await uploadSiteFileApi({ payload });
        return response;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const addCampaignsiteAction = ({ tenantId, authToken, userId, payload, addParams }) => async dispatch => {
    let addCampaignSite = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addCampaignSite = await addCampaignSiteApi({ tenantId, authToken, userId, payload, addParams });
        return addCampaignSite;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const deleteCampaignSiteAction = ({ tenantId, authToken, userId, deleteId }) => async dispatch => {
    let deleteCampaignSite = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteCampaignSite = await deleteCampaignSiteApi({ tenantId, authToken, userId, deleteId });
        return deleteCampaignSite && deleteCampaignSite.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const uploadBulkFileAction = (payload, advertiserId, accept) => async dispatch => {
    let response = {};
    try {
        // dispatch({
        //     type: BEGIN_AJAX_CALL,
        //     showSpinner: true,
        // });
        response = await uploadFileActionApi({ payload, advertiserId, accept });
        return response;
    } catch (err) {
    } finally {
        // dispatch({
        //     type: END_AJAX_CALL,
        //     showSpinner: false,
        // });
    }
};

export const getCampaignPerformanceReportDownloadAction = (tenantId, authToken, userId, campaignPerformanceReportReqBody) => async dispatch => {
    let campaignPerformanceReportDownload = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignPerformanceReportDownload = await getCampaignPerformanceReportDownloadApi({ tenantId, authToken, userId, campaignPerformanceReportReqBody });
        return (campaignPerformanceReportDownload || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getUserReachReportDownloadAction = (tenantId, authToken, userId, userReachReportReqBody) => async dispatch => {
    let userReachReportDownload = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        userReachReportDownload = await getUserReachReportDownloadApi({ tenantId, authToken, userId, userReachReportReqBody });
        return (userReachReportDownload || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getBidderReportDownloadAction = (tenantId, authToken, userId, bidderReportReqBody) => async dispatch => {
    let bidderDownloadReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        bidderDownloadReport = await getBidderReportDownloadApi({ tenantId, authToken, userId, bidderReportReqBody });
        return (bidderDownloadReport || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getMediaPlanningDownloadReportAction = (tenantId, authToken, userId, mediaPlanningReportReqBody) => async dispatch => {
    let mediaPlanningDownloadReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        mediaPlanningDownloadReport = await getMediaPlanningDownloadReportApi({ tenantId, authToken, userId, mediaPlanningReportReqBody });
        return (mediaPlanningDownloadReport || {})
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getMediaReportDownloadAction = (props) => async dispatch => {
    let mediaReportDownload = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        mediaReportDownload = await getMediaReportDownloadApi(props);
        return (mediaReportDownload || {});
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getRevenueReportDownloadAction = (tenantId, authToken, userId, revenueReportReqBody) => async dispatch => {
    let revenueDownloadReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        revenueDownloadReport = await getRevenueReportDownloadApi({ tenantId, authToken, userId, revenueReportReqBody });
        return (revenueDownloadReport || {})
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getQPSReportDownloadAction = (tenantId, authToken, userId, qpsReportReqBody) => async dispatch => {
    let qpsDownloadReport = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        qpsDownloadReport = await getQpsReportDownloadApi({ tenantId, authToken, userId, qpsReportReqBody });
        return (qpsDownloadReport || {})
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getCampaignPixelListAction = (tenantId, authToken, userId, advertiserId) => async dispatch => {
    let campaignPixelList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        campaignPixelList = await getCampaignPixelListApi(tenantId, authToken, userId, advertiserId);
        dispatch({
            type: GET_CAMPAIGN_PIXEL_LIST,
            campaignPixelList,
        });
        return (campaignPixelList || {})
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
}

//delete segment

export const deleteSegmentAction = (segmentObj) => async dispatch => {
    let deleteSegment = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        deleteSegment = await deleteSegmentApi(segmentObj);
        return (deleteSegment || {})
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const getAccountsListAction = (tenantId, authToken, userId) => async dispatch => {
    let accountsList = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        accountsList = await getAccountsListApi(tenantId, authToken, userId);
        dispatch({
            type: GET_ACCOUNTS_LIST,
            accountsList,
        });
        return accountsList
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const addAccountAction = (tenantId, authToken, userId, payload, addParams, id) => async dispatch => {
    let addAccount = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addAccount = await addAccountApi({ tenantId, authToken, userId, payload, addParams, id });
        return addAccount;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const editAccountAction = (tenantId, authToken, userId, editId) => async dispatch => {
    let editAccount = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        editAccount = await editAccountApi({ tenantId, authToken, userId, editId });
        return editAccount && editAccount;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const editUpTenantAction = (tenantId, authToken, userId, data, addParams, id, type) => async dispatch => {
    let check = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        check = await editUpTenantApi(tenantId, authToken, userId, data, addParams, id, type);
        dispatch({
            type: ADD_TENANT,
            check,
        });
        return check;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
}

export const addUpTenantAction = (tenantId, authToken, userId, response, data, type) => async dispatch => {
    let check = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        check = await addUpTenantApi(tenantId, authToken, userId, response, data, type);
        dispatch({
            type: ADD_TENANT,
            check,
        });
        return check;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
}

export const showSpinnerAction = (showSpinner = false) => async dispatch => {
    dispatch({ type: BEGIN_AJAX_CALL, showSpinner: showSpinner });
}

export const addPlatformAction = (tenantId, authToken, userId, payload, addParams) => async dispatch => {
    let addPlatform = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        addPlatform = await addPlatformApi({ tenantId, authToken, userId, payload, addParams });
        return addPlatform && addPlatform.responseCode;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const editPlatformAction = (tenantId, authToken, userId, editId) => async dispatch => {
    let editPlatform = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        editPlatform = await editPlatformApi({ tenantId, authToken, userId, editId });
        return editPlatform;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getPlatformListAction = ({ tenantId, authToken, userId }) => async dispatch => {
    let platformListAll = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        platformListAll = await getPlatformListApi({ tenantId, authToken, userId });
        dispatch({
            type: GET_PLATFORM_LIST_ALL,
            platformListAll,
        });
        return platformListAll;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

export const getPlatformAction = ({ tenantId, authToken, userId, id }) => async dispatch => {
    let platformData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        platformData = await getPlatformSingleListApi({ tenantId, authToken, userId, id });
        return platformData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// ------------campaign-> geolocation -> bulk Pincode upload  -------------

export const bulkPincodeCampaignAction = (tenantId, authToken, userId, payload) => async dispatch => {
    let bulkUploadData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        bulkUploadData = await bulkPincodeUploadCampaign({ tenantId, authToken, userId, payload });
        return bulkUploadData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


export const startSpinner = () => async dispatch => {
    dispatch({
        type: BEGIN_AJAX_CALL,
        showSpinner: true,
    });
}

export const stopSpinner = () => async dispatch => {
    dispatch({
        type: END_AJAX_CALL,
        showSpinner: false,
    });
}

// --------- Taxonomy Manager Bulk Title And Id Search ------------


export const taxonomyManagerBulkIdSearchAction = (taxonomyManagerTitleSearchObj) => async dispatch => {
    let taxonomyManagerTitleSearch = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        taxonomyManagerTitleSearch = await taxonomyManagerBulkIdSearchApi(taxonomyManagerTitleSearchObj);
        return taxonomyManagerTitleSearch;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// --------- Taxonomy Manager Title Search ------------


export const taxonomyManagerTitleSearchAction = (taxonomyManagerTitleSearchObj) => async dispatch => {
    let taxonomyManagerTitleSearch = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        taxonomyManagerTitleSearch = await taxonomyManagerTitleSearchApi(taxonomyManagerTitleSearchObj);
        return taxonomyManagerTitleSearch;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};


// --------- Audience Hierarchy Download------------


export const audienceHierarchyDownloadCSVAction = (audienceHierarchyDownloadCSVObj) => async dispatch => {
    let audienceHierarchyDownloadCSV = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        audienceHierarchyDownloadCSV = await audienceHierarchyDownloadCSVApi(audienceHierarchyDownloadCSVObj);
        return audienceHierarchyDownloadCSV;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

// ******************* SITE LIST CSV DATA PARSER FOR CAMPAIGN ***********************

export const siteListCSVDataAction = (payload) => async dispatch => {
    let siteListCSVDataCSV = {};
    console.log(payload, "IN ACTION")
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        siteListCSVDataCSV = await siteListCSVDataApi(payload);
        return siteListCSVDataCSV;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};

//---------------------Alert API-----------------
export const getAlertListAction = (tenantId, authToken, userId, params) => async dispatch => {
    let alertListData = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        alertListData = await getAlertListApi({ tenantId, authToken, userId, params });
        return alertListData;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
export const alertDeleteAction = ({ tenantId, authToken, userId, params }) => async dispatch => {
    let alertListDataDelete = {};
    try {
        dispatch({
            type: BEGIN_AJAX_CALL,
            showSpinner: true,
        });
        alertListDataDelete = await getAlertDeleteApi({ tenantId, authToken, userId, params });
        return alertListDataDelete;
    } catch (err) {
    } finally {
        dispatch({
            type: END_AJAX_CALL,
            showSpinner: false,
        });
    }
};
