import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import { get, isEmpty, uniq } from 'lodash';
import asyncRoute from '../helpers/hoc/asyncRoute';
import { dispatchAuthenticationDataAction } from '../actions';
import routes from '../routes';
import { getRoleAccessPermissions, getConfigurationDataAction, getCountryAction, getPincodeAllAction, getStateAllAction, getCityAllAction, logoutAction } from '../actions';

import { PrivateRoute } from '../utils/route-auth';
import { MODULE_PERMISSIONS } from '../constants/permission';

// compnennts import

const Header = asyncRoute(() => import('../components/Header'));
const SideNav = asyncRoute(() => import('../components/SideNav'));
const Footer = asyncRoute(() => import('../components/Footer'));
const Spinner = asyncRoute(() => import('../components/Spinner'));

// container import

const PreSignIn = asyncRoute(() => import('./Authentication/PreSignIn'));
const SignIn = asyncRoute(() => import('./Authentication/SignIn'));
const ForgotPassword = asyncRoute(() => import('./Authentication/ForgotPassword'));
const ResetPassword = asyncRoute(() => import('./Authentication/ResetPassword'));
const ION = asyncRoute(() => import('./ION'));
const Admin = asyncRoute(() => import('./Admin'));
const Audience = asyncRoute(() => import('./Audience'));
const Campaign = asyncRoute(() => import('./Campaign'));
const Creative = asyncRoute(() => import('./Creative'));
const Dashboard = asyncRoute(() => import('./Dashboard'));
const IO = asyncRoute(() => import('./IO'));
const NotAuthorize = asyncRoute(() => import('./NotAuthorize'));

class App extends Component {

    async componentWillMount() {
        let signInData = JSON.parse(localStorage.getItem('AuthData'));
        let preSignInData = JSON.parse(localStorage.getItem('PreSignInData'));
        let preSignInState = JSON.parse(localStorage.getItem('PreSignInState'));

        if (isEmpty(signInData) || isEmpty(preSignInData) || isEmpty(preSignInState)) {
            const AuthData = signInData || {};
            const { authToken } = AuthData;
            const { logoutAction, preSignInState, history, match, location } = this.props;
            if (location.pathname == `${match.path}/${routes.resetPassword}`) {
                let search = location.search
                const params = new URLSearchParams(search);
                const rp = params.get('rp');
                // const uid = params.get('uid');
                // history.push(`${match.path}/${routes.resetPassword}?rp=${rp}&uid=${uid}`);
                history.push(`${match.path}/${routes.resetPassword}?rp=${rp}`);
                return;
            }
            else {
                if (!(isEmpty(signInData) && isEmpty(preSignInData) && isEmpty(preSignInState))) {
                    logoutAction(preSignInState, authToken).then(() => {
                        history.push(`${match.path}/${routes.preSignIn}`);
                    });
                } else {
                    history.push(`${match.path}/${routes.preSignIn}`);
                }
                return;
            }
        }
        const { location, getRoleAccessPermissions, dispatchAuthenticationDataAction, getConfigurationDataAction, getCountryAction, getPincodeAllAction, getStateAllAction, getCityAllAction } = this.props;
        if (!isEmpty(signInData) || !isEmpty(preSignInData) || !isEmpty(preSignInState)) {

            getRoleAccessPermissions({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId });
            dispatchAuthenticationDataAction({ preSignInState, signInData, preSignInData });
            const segments = location.pathname.split('/'); // Split the URL by '/'
            const audienceSegment = segments[2];
            audienceSegment !== 'audience' && getConfigurationDataAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId });
            // getCountryAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId });
            // getStateAllAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId });
            // getCityAllAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId });
            // getPincodeAllAction({ tenantId: preSignInState.tenantId, authToken: signInData.authToken, userId: preSignInData.userId });
        }
    }

    render() {
        let isAuthenticated = JSON.parse(localStorage.getItem('AuthData'));
        const { showSpinner, match, showSpinnerConfig } = this.props;
        return (
            <>
                {(showSpinner || showSpinnerConfig) && <Spinner />}
                <Switch>
                    <Route exact path={`${match.path}`} render={() => <Redirect to={`${match.path}/${routes.preSignIn}`} />} />
                    <Route exact path={`${match.path}/${routes.preSignIn}`} component={PreSignIn} />
                    <Route exact path={`${match.path}/${routes.signIn}`} component={SignIn} />
                    <Route exact path={`${match.path}/${routes.forgotPassword}`} component={ForgotPassword} />
                    <Route exact path={`${match.path}/${routes.resetPassword}`} component={ResetPassword} />
                    <Route exact path={`${match.path}/${routes.notAuthorize}`} component={NotAuthorize} />


                    {
                        !isEmpty(isAuthenticated) &&
                        <>
                            <Header />
                            <SideNav />
                            {/* <Route exact path={`${match.path}`} render={() => <Redirect to={`${match.path}/${routes.containers.dashboard}`} />} /> */}
                            {/* <Route path={`${match.path}/${routes.containers.ion}`} component={ION} /> */}
                            {/* <Route path={`${match.path}/${routes.containers.io}`} component={IO} /> */}
                            {/* <Route path={`${match.path}/${routes.containers.admin}`} component={Admin} /> */}
                            {/* <Route path={`${match.path}/${routes.containers.audience}`} component={Audience} /> */}
                            {/* <Route path={`${match.path}/${routes.containers.campaign}`} component={Campaign} /> */}
                            {/* <Route path={`${match.path}/${routes.containers.creative}`} component={Creative} /> */}
                            {/* <Route path={`${match.path}/${routes.containers.dashboard}`} component={Dashboard} /> */}

                            <PrivateRoute path={`${match.path}/${routes.containers.dashboard}`} component={Dashboard}
                                permissions={MODULE_PERMISSIONS.DASHBOARD} defaultRoute={'dashboard'} />

                            <PrivateRoute path={`${match.path}/${routes.containers.io}`} component={IO}
                                permissions={MODULE_PERMISSIONS.IO} defaultRoute={'io'} />

                            <PrivateRoute path={`${match.path}/${routes.containers.campaign}`} component={Campaign}
                                permissions={MODULE_PERMISSIONS.CAMPAIGN} defaultRoute={'campaign'} />

                            <PrivateRoute path={`${match.path}/${routes.containers.creative}`} component={Creative}
                                permissions={MODULE_PERMISSIONS.CREATIVE} defaultRoute={'creative'} />

                            <PrivateRoute path={`${match.path}/${routes.containers.admin}`} component={Admin}
                                permissions={MODULE_PERMISSIONS.ADMIN} defaultRoute={'admin'} />

                            <PrivateRoute path={`${match.path}/${routes.containers.ion}`} component={ION}
                                permissions={MODULE_PERMISSIONS.EXCHANGE} defaultRoute={'exchange'} />

                            <PrivateRoute path={`${match.path}/${routes.containers.audience}`} component={Audience}
                                permissions={MODULE_PERMISSIONS.AUDIENCE} defaultRoute={'audience'} />
                        </>
                    }
                    <Footer />
                </Switch>
            </>
        );
    }
}

export default connect(
    state => ({
        showSpinnerConfig: get(state, 'app.showSpinnerConfig', false),
        showSpinner: get(state, 'app.showSpinner', false),
        configurationList: get(state, 'app.configurationData.responseList', []),
        tenantId: get(state, 'app.preSignInState.tenantId', ''),
        userId: get(state, 'app.preSignInData.userId', ''),
        authToken: get(state, 'app.signInData.authToken', '')
    }),
    {
        logoutAction,
        getConfigurationDataAction,
        dispatchAuthenticationDataAction,
        getCountryAction,
        getStateAllAction,
        getCityAllAction,
        getRoleAccessPermissions,
        getPincodeAllAction
    }
)(App);
