export const apiBaseUrl = window.location.protocol === 'https:' ? 'https://testbenchv2.torcai.com' : 'https://testbenchv2.torcai.com';
export const apiDruidBaseUrl = 'https://druid.torcai.com';
export const apiFileUploadBaseUrl = 'https://testbenchv2.torcai.com/torcai-ion';
export const version = '1.0.0';
export const domain = 'torcai.in';
export const constants = {};

export const soundTrackBaseUrl = window.location.protocol === 'https:' ? 'https://testbenchv3.torcai.com' : 'http://testbenchv3.torcai.com';

export const endPoints = {
  auth: '/auth/v3',
  geo: '/tai/geo/torcai',
  curated:'/curated',
  bidder: '/bidder/report',
  alerts:'/alerts',
  device:"/auth/v3/device/config",
  io: '/io',
  vastValidator: '/vast-validator',
  campaign: '/campaign',
  campaignPmp: '/campaign-pmp',
  datapartner: '/datapartner/report',
  creative: '/creative',
  medialist: '/medialist',
  data: '/data',
  cdp: 'cdp-audience',
  ionReport: '/ion/report',
  adUnitBulk: '/adunitbulk',
  adUnitMap: '/adunitmap',
  mediation: '/mediation',
  pmp: '/pmp',
  adgroup: '/adgroup',
  adunit: '/adunit',
  dashboard:'/dashboard/v3/oauth'
}
export const cdnUrl = window.location.protocol === 'https:' ? 'https://d1g8vw470g7bmg.cloudfront.net' : 'http://d1g8vw470g7bmg.cloudfront.net';

export const CONTEXT = '/torcai-ion';
// export const torcaiTracking = apiBaseUrl + '/audtrack/postback/track?'
export const torcaiTracking = soundTrackBaseUrl + '/audtrack/postback/track?'
// export const tomcatApiUrl = apiBaseUrl.replace(window.location.protocol, "") + '/content/dmp';
export const tomcatApiUrl = cdnUrl.replace(window.location.protocol, "") + '/dmp/ifrm';
// export const iFrameApiUrl = apiBaseUrl + '/dmp/jsv2';
export const iFrameApiUrl = cdnUrl + '/dmp/ifrm';
// export const imageApiUrl = apiBaseUrl + '/audtrack/param/track?';
export const imageApiUrl = soundTrackBaseUrl + '/audtrack/param/track?';
export const adUnitPublishLink = `https://drramakce.com/demotest/publishstg.html?adunit=`;
export const adUnitSingleJsLink = `https://testbenchv2.torcai.com/ad-server/v3/js/dfp/ionadtag.orig.js?`;
export const vilAdUnitPublishLink = `https://cdn.torcai.com/preview/vil.html?adunit=`;